<template>
  <div class="flex h-16 items-center justify-between">
    <div class="flex flex-1 items-center">
      <slot name="left" />
    </div>

    <div class="flex items-center gap-5 md:gap-10 lg:gap-16">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TemplateHeader',
};
</script>

<style scoped></style>
