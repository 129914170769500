import { createApp, h } from 'vue';
import { createAuth0 } from '@auth0/auth0-vue';

import App from './App.vue';

import i18n from '@/locales';
import createRouter from './router';
import store from './store';
import posthogPlugin from './plugins/posthog';

import '@/assets/css/tailwind.css';

const app = createApp({
  render: () => h(App),
});

app
  .use(createRouter(app))
  .use(i18n)
  .use(
    createAuth0({
      domain: process.env.VUE_APP_AUTH0_DOMAIN,
      clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
      authorizationParams: {
        redirect_uri: `${window.location.origin}/callback`,
        ui_locales: navigator.language,
      },
    })
  )
  .use(store);

app.config.performance = true;

// eslint-disable-next-line no-unused-vars
app.config.warnHandler = (msg, vm, trace) => {
  // eslint-disable-next-line no-console
  console.warn(msg, trace);
};

app.config.errorHandler = (error, vm, info) => {
  // eslint-disable-next-line no-console
  console.error('Uncaught error:', {
    error,
    vm,
    info,
  });
};

// Add Intercom script to head
const intercomScript = document.createElement('script');
intercomScript.type = 'text/javascript';
intercomScript.async = true;
intercomScript.src = `https://widget.intercom.io/widget/${process.env.VUE_APP_INTERCOM_APP_ID}`;
document.head.appendChild(intercomScript);

app.use(posthogPlugin);
app.mount('#app');

export default app;
