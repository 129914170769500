import { STORAGE_PREFIX } from '@/constants/storage';

export default function clearSessionData() {
  Object.entries(localStorage)
    .filter(([key]) => key.startsWith(STORAGE_PREFIX) || key === 'vuex')
    .forEach(([key]) => localStorage.removeItem(key));
  Object.entries(sessionStorage)
    .filter(([key]) => key.startsWith(STORAGE_PREFIX))
    .forEach(([key]) => sessionStorage.removeItem(key));
}
