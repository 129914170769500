<template>
  <div class="max-w-full animate-pulse overflow-hidden">
    <skeleton-loader-rectangle class="mb-6 w-40" />

    <div class="mb-8 flex w-full flex-col gap-6">
      <div class="flex h-8 flex-wrap gap-3">
        <skeleton-loader-rectangle class="w-10" />
        <skeleton-loader-rectangle class="w-12" />
        <skeleton-loader-rectangle class="w-12" />
        <skeleton-loader-rectangle class="w-10" />
        <skeleton-loader-rectangle class="w-10" />
      </div>
      <div class="flex flex-wrap gap-3">
        <skeleton-loader-rectangle class="w-20" />
        <skeleton-loader-rectangle class="w-16" />
        <skeleton-loader-rectangle class="w-32" />
        <skeleton-loader-rectangle class="w-24" />
        <skeleton-loader-rectangle class="w-10" />
      </div>
      <div class="flex flex-wrap gap-3">
        <skeleton-loader-rectangle class="w-12" />
        <skeleton-loader-rectangle class="w-16" />
        <skeleton-loader-rectangle class="w-48" />
        <skeleton-loader-rectangle class="w-16" />
        <skeleton-loader-rectangle class="w-20" />
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonLoaderRectangle from '@/components/skeleton-loader/SkeletonLoaderRectangle';

export default {
  name: 'StandardSkeletonLoader',

  components: { SkeletonLoaderRectangle },
};
</script>

<style scoped>
.base-table-row {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}
</style>
