<template>
  <template-header class="header-dealer text-white">
    <template v-slot:left>
      <div class="z-10 flex xl:hidden">
        <the-header-hamburger-menu />

        <router-link :to="{ name: 'Home' }" class="inline-flex">
          <img src="@/assets/images/pay-logotype-negative.svg" alt="Pay" />
        </router-link>
      </div>

      <div class="mx-5 hidden border-r border-white/25 pr-5 xl:block">
        <router-link :to="{ name: 'Home' }">
          <img src="@/assets/images/my-money-pay-negative.svg" alt="Pay" />
        </router-link>
      </div>

      <p class="hidden text-lg xl:block">{{ partnerName }}</p>
    </template>

    <template v-slot:right>
      <contact-details class="hidden md:flex" />
      <the-header-user-menu class="z-10" />
    </template>
  </template-header>
</template>

<script>
import { useStore } from 'vuex';
import TemplateHeader from '@/templates/TemplateHeader.vue';
import TheHeaderHamburgerMenu from '@/components/the-header/TheHeaderHamburgerMenu';
import TheHeaderUserMenu from '@/components/the-header/TheHeaderUserMenu.vue';
import ContactDetails from '@/components/the-header/ContactDetails.vue';

export default {
  name: 'TheHeaderMain',

  components: {
    TheHeaderHamburgerMenu,
    TemplateHeader,
    TheHeaderUserMenu,
    ContactDetails,
  },

  setup() {
    const { state } = useStore();
    const partnerName = state.user.partner.name;

    return {
      partnerName,
    };
  },
};
</script>

<style scoped>
.header-dealer {
  background-color: #0a3330;
}
</style>
