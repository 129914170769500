/**
 * Injects/replaces parameters in place of placeholder template variables
 * Example: application/{param1}/{param2} -> application/test1/test2
 *
 * @param {string} templateString
 * @param {Object} parameters Flat object with parameters to replace matches with
 * @returns {string} a new string with the query parameters inserted
 */
export default function replaceParameters(templateString, parameters) {
  return templateString.replace(/\{([a-z0-9]+)\}/gi, (match, paramKey) => {
    if (parameters[paramKey] === undefined || parameters[paramKey] === null) {
      throw Error(
        `InjectParams: Parameter ${paramKey} is undefined, input: ${templateString}, params: ${parameters}`
      );
    }

    return Array.isArray(parameters[paramKey]) &&
      parameters[paramKey].length > 1
      ? `${parameters[paramKey][0]}${parameters[paramKey]
          .slice(1)
          .map((a) => `&${paramKey}=${a}`)
          .join('')}`
      : parameters[paramKey];
  });
}
