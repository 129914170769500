export default {
  currency: {
    style: 'currency',
    currency: 'EUR',
    minimumIntegerDigits: 1,
    maximumSignificantDigits: 15,
  },
  interest: {
    style: 'percent',
    minimumFractionDigits: 2,
  },
  kilometer: {
    maximumSignificantDigits: 9,
  },
  percent: {
    style: 'percent',
  },
  round: {
    maximumFractionDigits: 0,
  },
};
