<template>
  <div class="mr-auto flex items-center justify-center">
    <div
      @click="canGoToPreviousPage && first()"
      class="rounded-sm"
      :class="{ 'hover-grey-background cursor-pointer': canGoToPreviousPage }"
    >
      <svg
        :class="
          canGoToPreviousPage
            ? 'fill-svg-active-color'
            : 'fill-svg-inactive-color'
        "
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="30"
        viewBox="0 0 32 30"
      >
        <path
          fill-rule="evenodd"
          d="M15.98 9.47c.269.268.29.69.067.984l-.067.076-4.42 4.42 4.42 4.42c.269.268.29.689.067.983l-.067.077c-.268.268-.69.29-.984.067l-.077-.067-4.95-4.95c-.268-.268-.29-.69-.066-.984l.067-.077 4.95-4.95c.292-.292.767-.292 1.06 0zm4 0c.269.268.29.69.067.984l-.067.076-4.42 4.42 4.42 4.42c.269.268.29.689.067.983l-.067.077c-.268.268-.69.29-.984.067l-.077-.067-4.95-4.95c-.268-.268-.29-.69-.066-.984l.067-.077 4.95-4.95c.292-.292.767-.292 1.06 0z"
        />
      </svg>
    </div>

    <div
      @click="canGoToPreviousPage && previous()"
      class="mx-1 rounded-sm"
      :class="{ 'hover-grey-background cursor-pointer': canGoToPreviousPage }"
    >
      <svg
        :class="
          canGoToPreviousPage
            ? 'fill-svg-active-color'
            : 'fill-svg-inactive-color'
        "
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="30"
        viewBox="0 0 32 30"
      >
        <path
          fill-rule="evenodd"
          d="M17.98 9.47c.269.268.29.69.067.984l-.067.076-4.42 4.419 4.42 4.42c.269.269.29.69.067.984l-.067.077c-.268.268-.69.29-.984.067l-.077-.067-4.95-4.95c-.268-.268-.29-.69-.066-.984l.067-.077 4.95-4.95c.292-.292.767-.292 1.06 0z"
        />
      </svg>
    </div>

    <p class="px-3 py-1">
      {{ pagination.currentPage + 1 }} av {{ pagination.totalPages }}
    </p>

    <div
      @click="canGoToNextPage && next()"
      class="mx-1 rounded-sm"
      :class="{ 'hover-grey-background cursor-pointer': canGoToNextPage }"
    >
      <svg
        :class="
          canGoToNextPage ? 'fill-svg-active-color' : 'fill-svg-inactive-color'
        "
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="30"
        viewBox="0 0 32 30"
      >
        <path
          fill-rule="evenodd"
          d="M14.02 9.47c-.269.268-.29.69-.067.984l.067.076 4.42 4.419-4.42 4.42c-.269.269-.29.69-.067.984l.067.077c.268.268.69.29.984.067l.077-.067 4.95-4.95c.268-.268.29-.69.066-.984l-.067-.077-4.95-4.95c-.292-.292-.767-.292-1.06 0z"
        />
      </svg>
    </div>

    <div
      @click="canGoToNextPage && last()"
      class="rounded-sm"
      :class="{ 'hover-grey-background cursor-pointer': canGoToNextPage }"
    >
      <svg
        :class="
          canGoToNextPage ? 'fill-svg-active-color' : 'fill-svg-inactive-color'
        "
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="30"
        viewBox="0 0 32 30"
      >
        <path
          fill-rule="evenodd"
          d="M11.92 9.47c.292-.293.767-.293 1.06 0l4.95 4.95.067.076c.224.294.201.716-.067.984l-4.95 4.95-.077.067c-.294.224-.715.201-.984-.067l-.067-.077c-.223-.294-.201-.715.067-.984l4.42-4.42-4.42-4.419-.067-.076c-.223-.294-.201-.716.067-.984zm4 0c.292-.293.767-.293 1.06 0l4.95 4.95.067.076c.224.294.201.716-.067.984l-4.95 4.95-.077.067c-.294.224-.715.201-.984-.067l-.067-.077c-.223-.294-.201-.715.067-.984l4.42-4.42-4.42-4.419-.067-.076c-.223-.294-.201-.716.067-.984z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'ThePagination',

  props: {
    pagination: Object,
    updatePage: Function,
  },

  setup(props, { emit }) {
    const lastPage = computed(() => props.pagination.totalPages - 1);

    const canGoToPreviousPage = computed(
      () => props.pagination.currentPage > 0
    );
    const canGoToNextPage = computed(
      () => props.pagination.currentPage < lastPage.value
    );

    function first() {
      emit('on-update-page', 0);
    }

    function previous() {
      emit('on-update-page', props.pagination.currentPage - 1);
    }

    function next() {
      emit('on-update-page', props.pagination.currentPage + 1);
    }

    function last() {
      emit('on-update-page', lastPage.value);
    }

    return {
      canGoToPreviousPage,
      canGoToNextPage,
      first,
      previous,
      next,
      last,
    };
  },
};
</script>

<style scoped>
.hover-grey-background:hover {
  background-color: #f2f2f2;
}

.fill-svg-active-color {
  fill: #2f2f2f;
}

.fill-svg-inactive-color {
  fill: #bebebe;
}
</style>
