<template>
  <button
    class="btn-text"
    :class="{ 'pointer-events-none': loading }"
    :disabled="disabled"
    :type="type"
    @click="onClick"
  >
    <img
      v-if="loading"
      class="animate-spin"
      :class="[leadingIconMarginClass, iconClass]"
      src="@/assets/images/loading-spinners/secondary-spinner.png"
      alt="loading"
    />
    <img
      v-else-if="leadingIcon"
      :class="[leadingIconMarginClass, iconClass]"
      :src="require('@/assets/images/icons/' + leadingIconUrl)"
      alt=""
    />

    <slot />
    <img
      v-if="postIcon"
      :class="[postIconMarginClass, iconClass]"
      :src="require('@/assets/images/icons/' + postIconUrl)"
      alt=""
    />
  </button>
</template>

<script>
import ButtonTypes from '@/constants/ButtonTypes';

const icons = {
  back: 'text-button-back-chevron.svg',
  download: 'download-icon.svg',
  info: 'info-icon.svg',
  pencil: 'pencil-icon.svg',
  'arrow-left': 'arrow-left-icon.svg',
  'arrow-right': 'arrow-right-icon.svg',
  'arrow-green-left': 'chevron-green-left.svg',
  'arrow-green-right': 'chevron-green-right.svg',
};
export default {
  name: 'ButtonText',

  props: {
    disabled: Boolean,
    leadingIcon: String,
    postIcon: String,
    loading: Boolean,
    iconClass: String,
    type: ButtonTypes,
  },

  setup(props, { emit, slots }) {
    const leadingIconUrl = icons[props.leadingIcon] ?? props.leadingIcon;
    const postIconUrl = icons[props.postIcon] ?? props.postIcon;
    const leadingIconMarginClass = slots.default ? 'mr-1' : '';
    const postIconMarginClass = slots.default ? 'ml-2' : '';

    const onClick = () => {
      if (props.loading) {
        return false;
      }

      return emit('on-click');
    };

    return {
      leadingIconUrl,
      postIconUrl,
      leadingIconMarginClass,
      postIconMarginClass,
      onClick,
    };
  },
};
</script>

<style scoped></style>
