import { ref } from 'vue';

export function useToggle() {
  const isVisible = ref(false);

  function toggleVisible(ev) {
    if (ev?.preventDefault) {
      ev.preventDefault();
    }
    isVisible.value = !isVisible.value;
  }

  function toggleVisibleOn() {
    isVisible.value = true;
  }

  function toggleVisibleOff() {
    isVisible.value = false;
  }

  return {
    isVisible,
    toggleVisible,
    toggleVisibleOn,
    toggleVisibleOff,
  };
}

export default { useToggle };
