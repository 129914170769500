<template>
  <div class="rounded-md px-3 py-2 text-center text-sm" :class="variantColor">
    <slot />
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'SimpleMessage',

  props: {
    variant: {
      type: String,
      default: 'disclaimer',
    },
  },
  setup(props) {
    const variantColor = computed(() => {
      switch (props.variant) {
        case 'action':
          return 'text-message-blue bg-message-blue-lighter';
        case 'alert':
          return 'text-message-yellow bg-message-yellow-lighter';
        case 'error':
          return 'text-message-red bg-message-red-lighter';
        case 'success':
          return 'text-message-green bg-message-green-lighter';
        default:
          return 'text-message-gray bg-message-gray-lighter';
      }
    });
    return { variantColor };
  },
};
</script>
