import Api from '@/proxies/Api';
import Proxy from '@/proxies/Proxy';

import createUrl from '@/proxies/createUrl';

const { TOKEN } = Api;

class TokenProxy extends Proxy {
  revokeToken() {
    const url = createUrl({
      host: TOKEN.HOST,
      endpoint: { path: TOKEN.REVOKE_TOKEN },
    });

    return this.post(url, {});
  }
}

export default TokenProxy;
