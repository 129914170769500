import { ref } from 'vue';

import { useToggle } from '@/compositions/useToggle';
import PartnerProxy from '@/proxies/PartnerProxy';

export function useKeyAccountManager() {
  const partnerProxy = new PartnerProxy();
  const {
    isVisible: loading,
    toggleVisibleOn: setLoadingTrue,
    toggleVisibleOff: setLoadingFalse,
  } = useToggle();

  const {
    isVisible: failed,
    toggleVisibleOn: setFailedTrue,
    toggleVisibleOff: setFailedFalse,
  } = useToggle();

  const keyAccountManager = ref({});

  const getKeyAccountManager = async () => {
    try {
      setFailedFalse();
      setLoadingTrue();
      keyAccountManager.value = await partnerProxy.getKAM();
    } catch (error) {
      setFailedTrue();
    } finally {
      setLoadingFalse();
    }
  };

  return {
    keyAccountManager,
    loading,
    failed,
    getKeyAccountManager,
  };
}

export default { useKeyAccountManager };
