<template>
  <div class="fixed right-0 top-0 z-20 w-full px-4 sm:w-96">
    <notification
      v-for="item in notifications"
      :key="item.id"
      :id="item.id"
      :notification="item"
    />
  </div>
</template>
<script>
import useNotifications from '@/compositions/useNotifications';
import Notification from '@/components/notifications/Notification.vue';

const { notifications } = useNotifications();

export default {
  components: { Notification },
  setup() {
    return { notifications };
  },
};
</script>
