import { createI18n } from 'vue-i18n';

import datetimeFormats from '@/locales/datetimeFormats.js';
import messages from '@/locales/messages';
import numberFormats from '@/locales/numberFormats.js';

const i18n = createI18n({
  legacy: false, // you must set `false`, to use Composition API
  locale: process.env.VUE_APP_LOCALE, // set locale
  fallbackLocale: 'sv-SE', // set fallback locale
  datetimeFormats,
  messages,
  numberFormats,
  // If you need to specify other options, you can set other options
  // ...
});

export default i18n;
