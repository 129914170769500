<template>
  <div
    class="relative z-10 mt-5 rounded border py-2 pl-4 pr-8 shadow-md"
    :class="typeClass"
  >
    <base-button
      class="absolute right-0 top-0"
      variant="simpleClose"
      @on-click="removeNotification(notification.id)"
    />
    <strong>{{ notification.title }}</strong
    ><br />
    {{ notification.message }} <br />
  </div>
</template>
<script>
import { inject } from 'vue';
import BaseButton from '@/components/BaseButton.vue';
import { TYPES } from '@/constants/notifications';
import { notifications } from '@/utils/symbols';

const typeClasses = {
  [TYPES.INFO]: 'bg-message-gray-lighter',
  [TYPES.WARNING]: 'bg-message-yellow-lighter',
  [TYPES.ERROR]: 'bg-message-red-lighter',
};

export default {
  components: { BaseButton },

  props: {
    notification: Object,
  },

  setup(props) {
    const removeNotification = inject(notifications.remove);
    return {
      removeNotification,
      typeClass:
        typeClasses[props.notification.type] ?? typeClasses[TYPES.INFO],
    };
  },
};
</script>
