<template>
  <button
    class="focus:btn-text.outline-none flex justify-center rounded font-semibold focus:ring"
    :class="[buttonSize, buttonStyle, pointerEventNone]"
    :disabled="disabled"
    :type="type"
    @click="onClick"
  >
    <div v-if="loading" class="mx-4 flex justify-center">
      <img
        v-if="isPrimaryButton"
        class="animate-spin"
        src="@/assets/images/loading-spinners/primary-spinner.png"
        alt="loading"
      />
      <img
        v-else
        class="animate-spin"
        src="@/assets/images/loading-spinners/secondary-spinner.png"
        alt="loading"
      />
    </div>
    <img
      v-else-if="isCloseButton"
      src="@/assets/images/icons/close-green-icon.svg"
      alt=""
    />
    <slot v-else />
  </button>
</template>

<script>
import { computed } from 'vue';

import ButtonTypes from '@/constants/ButtonTypes';

export default {
  name: 'BaseButton',

  props: {
    disabled: Boolean,
    loading: Boolean,
    size: String,
    variant: String,
    type: ButtonTypes,
  },

  setup(props, { emit }) {
    const buttonSize = computed(() => {
      switch (props.size) {
        case 'large':
          return 'py-4';
        default:
          return 'py-2';
      }
    });

    const buttonStyle = computed(() => {
      switch (props.variant) {
        case 'primary':
          return 'btn-primary p-4';
        case 'secondary':
          return 'btn-secondary p-4';
        case 'simpleClose':
          return 'px-2';
        case 'close':
          return 'bg-opacity-25 py-4 px-4 rounded-full hover:bg-opacity-100 btn-secondary ';
        default:
          return '';
      }
    });

    const pointerEventNone = computed(() => {
      if (props.loading) {
        return 'pointer-events-none';
      }
      return '';
    });

    const isPrimaryButton = computed(() => props.variant === 'primary');
    const isCloseButton = computed(() =>
      ['close', 'simpleClose'].includes(props.variant)
    );

    function onClick() {
      if (props.loading) {
        return false;
      }

      return emit('on-click');
    }

    return {
      buttonSize,
      buttonStyle,
      pointerEventNone,
      isPrimaryButton,
      isCloseButton,
      onClick,
    };
  },
};
</script>

<style scoped></style>
