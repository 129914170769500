import set from 'lodash.set';

export default {
  state: {
    formErrors: {},
    formHelpers: {},
    formSharedFieldValues: {},
  },
  mutations: {
    POST_FORM_ERROR: (state, payload) => {
      state.formErrors[payload.formType] = payload.errors;
    },
    REMOVE_FORM_ERROR: (state, payload) => {
      const index = state.formErrors[payload.formType]?.findIndex(
        (error) => error?.field === payload.fieldName
      );
      if (index > -1) {
        state.formErrors[payload.formType].splice(index, 1);
      }
    },
    POST_FORM_HELPER: (state, payload) => {
      set(
        state.formHelpers,
        `${payload.formType}.${payload.fieldName}`,
        payload.value
      );
    },
    POST_FORM_SHARED_FIELD_VALUE: (state, payload) => {
      set(
        state.formSharedFieldValues,
        `${payload.formType}.${payload.fieldName}`,
        payload.value
      );
    },
    RESET_FORM_STATE: (state, payload) => {
      state.formErrors = {
        ...state.formErrors,
        [payload.formType]: [],
      };
      state.formHelpers = {
        ...state.formHelpers,
        [payload.formType]: {},
      };
      state.formSharedFieldValues = {
        ...state.formSharedFieldValues,
        [payload.formType]: {},
      };
    },
  },
  actions: {
    SET_FORM_ERROR: (context, error) => {
      context.commit('POST_FORM_ERROR', error);
    },
    REMOVE_FORM_ERROR: (context, error) => {
      context.commit('REMOVE_FORM_ERROR', error);
    },
    SET_FORM_HELPER: (context, helper) => {
      context.commit('POST_FORM_HELPER', helper);
    },
    SET_FORM_SHARED_FIELD_VALUE: (context, payload) => {
      context.commit('POST_FORM_SHARED_FIELD_VALUE', payload);
    },
    RESET_FORM_STATE: (context, payload) => {
      context.commit('RESET_FORM_STATE', payload);
    },
  },
};
