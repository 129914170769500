import Api from '@/proxies/Api';
import Proxy from '@/proxies/Proxy';

import createUrl from '@/proxies/createUrl';

const { POINT_SHOP } = Api;

class PointShopProxy extends Proxy {
  getArticles(params, countryCode, filter = '') {
    const url = createUrl({
      host: POINT_SHOP.HOST,
      endpoint: {
        path: POINT_SHOP.ARTICLES,
        query: `page={page}&size={size}&sort={sort}${filter}&countryCode=${countryCode}`,
      },
      params,
    });

    return this.get(url);
  }

  getArticleById(articleId, countryCode) {
    const url = createUrl({
      host: POINT_SHOP.HOST,
      endpoint: {
        path: `${POINT_SHOP.ARTICLE}`,
        query: `countryCode=${countryCode}`,
      },
      pathParams: { articleId },
    });

    return this.get(url);
  }

  createOrder(order) {
    const url = createUrl({
      host: POINT_SHOP.HOST,
      endpoint: { path: POINT_SHOP.ORDERS },
    });

    return this.post(url, order);
  }

  getShopAccount() {
    const url = createUrl({
      host: POINT_SHOP.HOST,
      endpoint: { path: POINT_SHOP.ACCOUNT },
    });

    return this.get(url);
  }
}

export default PointShopProxy;
