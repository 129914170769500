<template>
  <div>
    <the-header-main v-if="isAuthenticated" />

    <div class="flex">
      <the-navigation v-if="isAuthenticated" class="hidden xl:block" />

      <router-view
        class="m-auto min-h-screen flex-auto flex-col px-5 pb-20 pt-12"
        :class="[useTabletWidth ? 'max-w-2xl' : 'max-w-5xl']"
      />
    </div>
  </div>
</template>

<style></style>
<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useAuth0 } from '@auth0/auth0-vue';

import TheHeaderMain from '@/components/the-header/TheHeaderMain';
import TheNavigation from '@/components/TheNavigation';

export default {
  name: 'StandardLayout',

  components: { TheHeaderMain, TheNavigation },

  setup() {
    const route = useRoute();
    const { isAuthenticated } = useAuth0();

    const useTabletWidth = computed(() => route.meta.maxWidth === 'tablet');

    return {
      useTabletWidth,
      isAuthenticated,
    };
  },
};
</script>
