import Api from '@/proxies/Api';
import Proxy from '@/proxies/Proxy';

import createUrl from '@/proxies/createUrl';

const { PARTNER } = Api;

class PartnerProxy extends Proxy {
  getPartner() {
    const url = createUrl({
      host: PARTNER.HOST,
      endpoint: { path: PARTNER.DEALER },
    });

    return this.get(url);
  }

  getPartnerEmployees(params) {
    const url = createUrl({
      host: PARTNER.HOST,
      endpoint: {
        path: PARTNER.EMPLOYEES,
        query: 'page={page}&size={size}&name={name}',
      },
      params,
    });

    return this.get(url);
  }

  getFacilities() {
    const url = createUrl({
      host: PARTNER.HOST,
      endpoint: { path: PARTNER.FACILITY },
    });

    return this.get(url);
  }

  getKAM() {
    const url = createUrl({
      host: PARTNER.HOST,
      endpoint: {
        path: PARTNER.KAM,
      },
    });

    return this.get(url);
  }
}

export default PartnerProxy;
