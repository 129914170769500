export const STORAGE_PREFIX = 'mymoney';

export const STORAGE_IDS = {
  BLANCO_LIST_STATE: `${STORAGE_PREFIX}-blanco_list_state`,
  LEASING_LIST_STATE: `${STORAGE_PREFIX}-leasing_list_state`,
  INVENTORY_FINANCING_LIST_STATE: `${STORAGE_PREFIX}-inventory-financing_list_state`,
  POINT_SHOP_LIST_STATE: `${STORAGE_PREFIX}-point-shop_list_state`,
  POINT_SHOP_CART_STATE: `${STORAGE_PREFIX}-point-shop_cart_state`,
};

export default {
  STORAGE_PREFIX,
  STORAGE_IDS,
};
