/* eslint-disable no-unused-vars */
import { nextTick } from 'vue';
import { createRouter as createVueRouter, createWebHistory } from 'vue-router';
import { createAuthGuard } from '@auth0/auth0-vue';
import posthog from 'posthog-js';
import Layouts from '@/constants/Layouts';
import Roles from '@/constants/Roles';
import i18n from '@/locales';
import store from '@/store';
import Intercom from '@/utils/intercom';

// These imports are here to be preloaded for all pages
import BaseButton from '@/components/BaseButton.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';
import BaseDropdown from '@/components/BaseDropdown.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseModal from '@/components/BaseModal.vue';
import BaseRadioButton from '@/components/BaseRadioButton.vue';
import BaseRangeSlider from '@/components/BaseRangeSlider.vue';
import ButtonText from '@/components/ButtonText.vue';
import SearchField from '@/components/SearchField.vue';
import SimpleMessage from '@/components/SimpleMessage.vue';
import ThePagination from '@/components/ThePagination.vue';

export default function createRouter(app) {
  const { t: translation } = i18n.global;
  const isAppInMaintenance = process.env.VUE_APP_IN_MAINTENANCE === 'true';

  function checkAuthGuard() {
    if (isAppInMaintenance) {
      return true;
    }
    return createAuthGuard(app);
  }

  const router = createVueRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
      {
        path: '/login',
        redirect: { name: 'Home' },
      },
      {
        path: '/activate-account/:token',
        name: 'ActivateAccount',
        component: () =>
          import(
            /* webpackChunkName: "activate-account" */ '@/views/ActivateAccount.vue'
          ),
        meta: {
          layout: Layouts.LOGIN,
          title: translation('general.pageTitle.activateAccount'),
        },
      },
      {
        path: '/callback',
        name: 'Callback',
        component: () =>
          import(/* webpackChunkName: "callback" */ '@/views/Callback.vue'),
      },
      {
        path: '/',
        name: 'Home',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        beforeEnter: checkAuthGuard(),
        meta: {
          role: Roles.BASIC,
          title: translation('general.pageTitle.home'),
        },
      },
      {
        path: '/blanco',
        name: 'Blanco',
        component: () =>
          import(/* webpackChunkName: "blanco" */ '@/views/Blanco.vue'),
        beforeEnter: checkAuthGuard(),
        meta: {
          role: Roles.BLANCO,
          title: translation('general.pageTitle.blancoList'),
        },
      },
      {
        path: '/blanco/:id',
        name: 'BlancoDetails',
        component: () =>
          import(/* webpackChunkName: "blanco" */ '@/views/BlancoDetails.vue'),
        beforeEnter: checkAuthGuard(),
        meta: {
          maxWidth: 'tablet',
          role: Roles.BLANCO,
          title: translation('general.pageTitle.blancoDetails'),
        },
      },
      {
        path: '/blanco/apply',
        name: 'BlancoApply',
        component: () =>
          import(/* webpackChunkName: "blanco" */ '@/views/BlancoApply.vue'),
        beforeEnter: checkAuthGuard(),
        meta: {
          layout: Layouts.FULL_SCREEN,
          role: Roles.BLANCO,
          title: translation('general.pageTitle.blancoApply'),
        },
      },
      {
        path: '/leasing',
        name: 'Leasing',
        component: () =>
          import(/* webpackChunkName: "leasing" */ '@/views/Leasing.vue'),
        beforeEnter: checkAuthGuard(),
        meta: {
          role: Roles.LEASING,
          title: translation('general.pageTitle.leasingList'),
        },
      },
      {
        path: '/leasing/:id',
        name: 'LeasingDetails',
        component: () =>
          import(
            /* webpackChunkName: "leasing" */ '@/views/LeasingDetails.vue'
          ),
        beforeEnter: checkAuthGuard(),
        meta: {
          maxWidth: 'tablet',
          role: Roles.LEASING,
          title: translation('general.pageTitle.leasingDetails'),
        },
      },
      {
        path: '/leasing/apply',
        name: 'LeasingApply',
        component: () =>
          import(/* webpackChunkName: "leasing" */ '@/views/LeasingApply.vue'),
        beforeEnter: checkAuthGuard(),
        meta: {
          layout: Layouts.FULL_SCREEN,
          role: Roles.LEASING,
          title: translation('general.pageTitle.leasingApply'),
          backTarget: 'Leasing',
        },
      },
      {
        path: '/inventory-financing',
        name: 'InventoryFinancing',
        component: () =>
          import(
            /* webpackChunkName: "inventory-financing" */ '@/views/InventoryFinancing.vue'
          ),
        beforeEnter: checkAuthGuard(),
        meta: {
          role: Roles.BUSINESS_FINANCE,
          title: translation('general.pageTitle.inventoryFinancingList'),
        },
      },
      {
        path: '/inventory-financing/:id',
        name: 'InventoryFinancingDetails',
        component: () =>
          import(
            /* webpackChunkName: "inventory-financing" */ '@/views/inventory-financing-details/Details.vue'
          ),
        beforeEnter: checkAuthGuard(),
        meta: {
          maxWidth: 'tablet',
          role: Roles.BUSINESS_FINANCE,
          title: translation('general.pageTitle.inventoryFinancingDetails'),
        },
      },
      {
        path: '/inventory-financing/:leadId/payment/:invoiceId',
        name: 'InventoryFinancingPayment',
        component: () =>
          import(
            /* webpackChunkName: "inventory-financing" */ '@/views/inventory-financing-details/Payment.vue'
          ),
        beforeEnter: checkAuthGuard(),
        meta: {
          maxWidth: 'tablet',
          role: Roles.BUSINESS_FINANCE,
          title: translation('general.pageTitle.inventoryFinancingPayment'),
        },
      },
      {
        path: '/inventory-financing/:leadId/payment/:invoiceId/finshark',
        name: 'InventoryFinancingPaymentFinshark',
        component: () =>
          import(
            /* webpackChunkName: "inventory-financing" */ '@/views/inventory-financing-details/PaymentFinshark.vue'
          ),
        beforeEnter: checkAuthGuard(),
        meta: {
          maxWidth: 'tablet',
          role: Roles.BUSINESS_FINANCE,
          title: translation('general.pageTitle.inventoryFinancingPayment'),
        },
      },
      {
        path: '/inventory-financing/apply',
        name: 'InventoryFinancingApply',
        component: () =>
          import(
            /* webpackChunkName: "inventory-financing" */ '@/views/InventoryFinancingApply.vue'
          ),
        beforeEnter: checkAuthGuard(),
        meta: {
          layout: Layouts.FULL_SCREEN,
          role: Roles.BUSINESS_FINANCE,
          title: translation('general.pageTitle.inventoryFinancingApply'),
          backTarget: 'InventoryFinancing',
        },
      },
      {
        path: '/point-shop',
        name: 'PointShop',
        component: () =>
          import(/* webpackChunkName: "point-shop" */ '@/views/PointShop.vue'),
        beforeEnter: checkAuthGuard(),
        meta: {
          role: Roles.POINT_SHOP,
          title: translation('general.pageTitle.pointShopList'),
        },
      },
      {
        path: '/point-shop/article/:id',
        name: 'PointShopArticle',
        component: () =>
          import(
            /* webpackChunkName: "point-shop" */ '@/views/PointShopArticle.vue'
          ),
        beforeEnter: checkAuthGuard(),
        meta: {
          maxWidth: 'tablet',
          role: Roles.POINT_SHOP,
          title: translation('general.pageTitle.pointShopArticle'),
        },
      },
      {
        path: '/point-shop/checkout',
        name: 'PointShopCheckout',
        component: () =>
          import(
            /* webpackChunkName: "point-shop" */ '@/views/PointShopCheckout.vue'
          ),
        beforeEnter: checkAuthGuard(),
        meta: {
          maxWidth: 'tablet',
          role: Roles.POINT_SHOP,
          title: translation('general.pageTitle.pointShopCheckout'),
        },
      },
      {
        path: '/price-tag',
        name: 'PriceTag',
        component: () =>
          import(/* webpackChunkName: "blanco" */ '@/views/PriceTag.vue'),
        beforeEnter: checkAuthGuard(),
        meta: {
          role: Roles.BLANCO,
          title: translation('general.pageTitle.priceTag'),
        },
      },
      {
        path: '/report',
        name: 'Report',
        component: () =>
          import(/* webpackChunkName: "report" */ '@/views/Report.vue'),
        beforeEnter: checkAuthGuard(),
        meta: {
          role: Roles.BUSINESS_FINANCE,
          title: translation('general.pageTitle.report'),
        },
        children: [
          {
            path: '',
            name: 'ReportRedirect',
            redirect: {
              name: 'ReportActiveBusinessFinanceStatement',
            },
          },
          {
            path: 'business-finance/active-statement',
            name: 'ReportActiveBusinessFinanceStatement',
            component: () =>
              import(
                /* webpackChunkName: "report" */ '@/views/ReportActiveBusinessFinanceStatement.vue'
              ),
            meta: {
              role: Roles.BUSINESS_FINANCE,
            },
          },
          {
            path: '',
            name: 'ReportRedirect',
            redirect: {
              name: 'ReportBusinessFinanceMonthlyStatement',
            },
          },
          {
            path: 'business-finance/monthly-statement',
            name: 'ReportBusinessFinanceMonthlyStatement',
            component: () =>
              import(
                /* webpackChunkName: "report" */ '@/views/ReportBusinessFinanceMonthlyStatement.vue'
              ),
            meta: {
              role: Roles.BUSINESS_FINANCE,
            },
          },
        ],
      },
      {
        path: '/commission',
        name: 'Commission',
        component: () =>
          import(/* webpackChunkName: "commission" */ '@/views/Commission.vue'),
        beforeEnter: checkAuthGuard(),
        meta: {
          role: Roles.COMMISSION,
          title: translation('general.pageTitle.commission'),
        },
      },
      {
        path: '/organisation',
        name: 'Organisation',
        component: () =>
          import(
            /* webpackChunkName: "organisation" */ '@/views/Organisation.vue'
          ),
        beforeEnter: checkAuthGuard(),
        meta: {
          role: Roles.ORGANIZATION_ADMIN,
          title: translation('general.pageTitle.organisation'),
        },
      },
      {
        path: '/user',
        name: 'User',
        component: () =>
          import(/* webpackChunkName: "user" */ '@/views/User.vue'),
        beforeEnter: checkAuthGuard(),
        children: [
          {
            path: 'my-profile',
            name: 'UserMyProfile',
            component: () =>
              import(
                /* webpackChunkName: "my-profile" */ '@/views/MyProfile.vue'
              ),
            meta: {
              title: translation('general.pageTitle.userMyProfile'),
            },
          },
        ],
        meta: {
          maxWidth: 'tablet',
          role: Roles.BASIC,
          title: translation('general.pageTitle.user'),
        },
      },
    ],
  });

  router.beforeResolve(async () => {
    const auth0 = app.config.globalProperties.$auth0;

    const { isAuthenticated } = auth0;

    if (isAuthenticated.value) {
      if (!store.state.user.informationFetched) {
        await store.dispatch('user/syncInformation');
      }

      const { intercomUserId, intercomUserAuthCode } = store.state.user;
      const languageCode = store.getters['user/getLanguageCode'];
      Intercom.boot(intercomUserId, intercomUserAuthCode, languageCode);
    }
  });

  router.beforeEach((to, from, next) => {
    store.commit('router/resetLoading');

    const matchesAnyRoute = (routeToCheck) => {
      return router.getRoutes().some((route) => {
        if (route.match) {
          return route.match(routeToCheck);
        }
        const regexPath = route.path
          .replace(/:([\w]+)/g, '([^/]+)')
          .replace('*', '.*');
        const regex = new RegExp(`^${regexPath}$`);
        return regex.test(routeToCheck.path);
      });
    };

    if (!matchesAnyRoute(to)) {
      next({ name: 'Home' });
      return;
    }

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta?.title);

    if (isAppInMaintenance) {
      document.title = `${translation(
        'general.pageTitle.titlePrefix'
      )} - ${translation('general.pageTitle.maintenance')}`;
    }
    // If a route with a title was found, set the document (page) title to that value.
    else if (nearestWithTitle) {
      document.title = `${translation('general.pageTitle.titlePrefix')} - ${
        nearestWithTitle.meta.title
      }`;
    }

    setTimeout(() => window.scrollTo(0, 0), 100);

    next();
  });

  router.afterEach((to) => {
    // Callback will redirect to Home and should not set loading as finished
    if (to.name !== 'Callback') {
      store.commit('router/finishedLoading');
    }

    nextTick(() => {
      posthog.capture('$pageview', {
        $current_url: to.fullPath,
      });
    });
  });

  return router;
}
