<template>
  <slot />
</template>
<script>
import { provide } from 'vue';
import { notifications } from '@/utils/symbols';
import useNotifications from '@/compositions/useNotifications';

const { addNotification, removeNotification } = useNotifications();

export default {
  setup() {
    provide(notifications.add, addNotification);
    provide(notifications.remove, removeNotification);

    return {};
  },
};
</script>
