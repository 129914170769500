<template>
  <div
    class="mymoney-primary-text-color select-none rounded border border-gray-400 bg-white py-2 shadow-dropdown"
  >
    <div
      v-for="(group, index) in options"
      :key="index"
      :class="{ 'base-dropdown-list-group-divider': groupDivider }"
    >
      <div
        v-if="group.title"
        class="base-dropdown-list-group-title flex h-7 px-3.5 py-0 font-bold uppercase tracking-wide"
      >
        <p class="my-auto">
          {{ group.title }}
        </p>
      </div>

      <div
        v-for="item in group.options"
        :key="item.value"
        class="flex h-9 cursor-pointer px-3.5 py-0"
        :class="{
          'text-primary-accent': isSelected(item.value),
        }"
        :data-automation-id="`option-${item.value}`"
        @click="onClickItem(item)"
      >
        <p class="my-auto flex items-center">
          <img
            v-if="item.leadIcon"
            class="mr-2"
            :src="require('@/assets/images/icons/' + item.leadIcon)"
            alt=""
          />
          {{ item.label }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseDropdownList',

  props: {
    groupDivider: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: false,
    },
    selected: [Number, String],
  },

  methods: {
    isSelected(value) {
      return this.selected === value;
    },

    onClickItem(item) {
      this.$emit('on-click', item);
    },
  },
};
</script>

<style scoped>
.base-dropdown-list-group-divider:not(:first-child) {
  border-top: 1px solid #e6e6e6;
  margin-top: 0.312rem;
  padding-top: 0.312rem;
}

.base-dropdown-list-group-title {
  font-size: 0.625rem;
  color: #999999;
}

.base-dropdown-list-group-item:hover {
  background-color: #f2f2f2;
}
</style>
