import Intercom from '@/utils/intercom';
import clearSessionData from '@/proxies/utils/clearSessionData';
import app from '@/main';

export default function logOut() {
  const auth0 = app.config.globalProperties.$auth0;
  Intercom.shutdown();
  clearSessionData();
  auth0.logout({ logoutParams: { returnTo: window.location.origin } });
}
