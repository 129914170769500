import axios from 'axios';
import app from '@/main';

export function AxiosWithInterceptors() {
  const openEndpoints = ['/user-invitations/consume'];

  function requestInterceptor() {
    axios.interceptors.request.use(
      (config) => {
        const newConfig = config;
        if (newConfig.headers === null) {
          newConfig.headers = {};
        }

        newConfig.headers['access-control-allow-origin'] = '*';

        if (config.headers.Accept === 'application/pdf') {
          newConfig.responseType = 'arraybuffer';
        }

        return newConfig;
      },
      (error) => Promise.reject(error)
    );
  }

  function oauthTokenInterceptor() {
    axios.interceptors.request.use(
      async (config) => {
        const extraConfigs = {
          ...config,
          headers: config?.headers ?? {},
        };

        const isOpenEndpoint = openEndpoints.some((endpoint) =>
          extraConfigs.url.endsWith(endpoint)
        );

        if (!isOpenEndpoint) {
          try {
            const token =
              await app.config.globalProperties.$auth0?.getAccessTokenSilently();
            extraConfigs.headers.Authorization = `Bearer ${token}`;
          } catch {
            throw new Error('unauthorized');
          }
        }

        return extraConfigs;
      },
      (err) => Promise.reject(err)
    );
  }

  function useAllInterceptors() {
    requestInterceptor();
    oauthTokenInterceptor();
  }

  useAllInterceptors();

  return {
    axios,
  };
}

export default { AxiosWithInterceptors };
