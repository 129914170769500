<template>
  <div
    ref="root"
    class="grey-overlay fixed inset-0 z-50 flex grow flex-col"
    :class="{ 'sm:p-5': !fullscreen }"
  >
    <div
      class="relative m-auto flex flex-col overflow-auto bg-white shadow-lg sm:p-5"
      :class="[
        fullscreen ? 'size-full justify-center ' : 'rounded-lg',
        desktopWidth && `xl:${desktopWidth}`,
      ]"
    >
      <base-button
        v-if="showCloseButton"
        class="right-0 top-0 mr-5 mt-5 w-12 self-end sm:absolute"
        variant="close"
        @on-click="onClickCloseModal"
      />

      <div class="m-auto p-4 sm:p-5">
        <p v-if="title" class="font-bold" :class="[titleSize, titleAlign]">
          {{ title }}
        </p>

        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import BaseButton from '@/components/BaseButton';

export default {
  name: 'BaseModal',

  components: { BaseButton },

  props: {
    fullscreen: Boolean,
    showCloseButton: Boolean,
    closeOnClickOutside: Boolean,
    closeOnEscape: Boolean,
    title: String,
    titleSize: {
      type: String,
      default: 'text-3xl',
    },
    titleAlign: {
      type: String,
      default: 'text-center',
    },
    desktopWidth: String,
  },

  setup(props, { emit }) {
    const root = ref(null);

    function onClickCloseModal() {
      emit('on-click-close-modal');
    }

    function checkClickOutside(e) {
      if (e.target === root.value) {
        onClickCloseModal();
      }
    }

    function checkKeypress(e) {
      if (e.keyCode === 27) {
        onClickCloseModal();
      }
    }

    onMounted(() => {
      if (props.closeOnClickOutside) {
        document.addEventListener('click', checkClickOutside);
      }
      if (props.closeOnEscape) {
        window.addEventListener('keyup', checkKeypress);
      }
    });

    onUnmounted(() => {
      window.removeEventListener('keyup', checkKeypress);
      document.removeEventListener('click', checkClickOutside);
    });

    return {
      root,
      onClickCloseModal,
    };
  },
};
</script>

<style scoped></style>
