/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint-disable no-empty-pattern */
import { ref } from 'vue';

import AccountProxy from '@/proxies/AccountProxy';
import PointShopProxy from '@/proxies/PointShopProxy';
import Roles from '@/constants/Roles';
// initial state
const state = () => ({
  informationFetched: ref(false),
  id: null,
  firstName: null,
  lastName: null,
  phone: null,
  email: null,
  facility: null,
  partner: null,
  roles: null,
  intercomUserId: null,
  intercomUserAuthCode: null,
  pointBalance: null,
  language: null,
});

// getters
const getters = {
  fullName: (state) => `${state.firstName} ${state.lastName}`,

  initials: (state) => `${state.firstName?.[0]}${state.lastName?.[0]}`,

  availableLanguageCodes: (state) => state.language.availableLanguageCodes,

  getLanguageCode: (state) =>
    state.language.selectedLanguageCode?.toLowerCase() ||
    process.env.VUE_APP_HTML_LANG,

  getLocale: (state, getter) =>
    `${getter.getLanguageCode}-${process.env.VUE_APP_COUNTRY_CODE}`,

  userHasAnyOfRoles:
    (state) =>
    (...roles) =>
      state.roles.some((userRole) => roles.includes(userRole)),
};

// actions
const actions = {
  async syncInformation({ commit, dispatch, getters: getter }) {
    try {
      const accountProxy = new AccountProxy();
      const userInformation = await accountProxy.getMe();

      await commit('storeUserData', userInformation);

      if (getter.userHasAnyOfRoles(Roles.POINT_SHOP)) {
        await dispatch('syncPointBalance');
      }

      commit('updateInformationFetched', true);
    } catch (error) {
      throw new Error(error);
    }
  },

  async syncPointBalance({ commit }) {
    try {
      const pointShopProxy = new PointShopProxy();
      const response = await pointShopProxy.getShopAccount();

      commit('setPointBalance', response.balance);
    } catch (error) {
      throw new Error(error);
    }
  },

  updateUserPhoneNumber(context, phoneNumber) {
    context.commit('updateUserPhoneNumber', phoneNumber);
  },
};

// mutations
const mutations = {
  clearUser(state) {
    state.id = null;
    state.firstName = null;
    state.lastName = null;
    state.phone = null;
    state.email = null;
    state.facility = null;
    state.partner = null;
    state.roles = null;
    state.intercomUserId = null;
    state.intercomUserAuthCode = null;
    state.informationFetched = false;
    state.pointBalance = false;
  },

  setPointBalance(state, pointBalance) {
    state.pointBalance = pointBalance;
  },

  updateUserPhoneNumber(state, phoneNumber) {
    state.phone = phoneNumber;
  },

  async storeUserData(state, information) {
    state.id = information.id;
    state.firstName = information.firstname;
    state.lastName = information.lastname;
    state.phone = information.phone;
    state.email = information.email;
    state.facility = information.facility;
    state.partner = information.dealer;
    state.roles = information.grantedRoles;
    state.intercomUserId = information.intercomUserId;
    state.intercomUserAuthCode = information.intercomUserAuthCode;
    state.language = information.language;
  },

  updateInformationFetched(state, informationFetched) {
    state.informationFetched = informationFetched;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
