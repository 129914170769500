<template>
  <base-modal :showCloseButton="true" @on-click-close-modal="onClickCloseModal">
    <standard-skeleton-loader v-if="loading" />
    <div v-else class="mt-10 max-w-4xl">
      <div class="mymoney-primary-text-color space-y-5 text-sm">
        <div>
          <p class="font-semibold">
            {{ translation('general.contactKeyAccountManagerModal.noAccess') }}
          </p>
          <p class="mt-1">
            {{
              translation('general.contactKeyAccountManagerModal.kamName', {
                kamName: kamName,
              })
            }}
          </p>
          <p class="mt-1 flex font-semibold">
            <img
              src="@/assets/images/icons/phone-call.svg"
              alt=""
              class="mr-2"
            />
            <a :href="`tel:${kamPhoneNumber}`">{{ kamPhoneNumber }}</a>
          </p>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import BaseModal from '@/components/BaseModal';
import { useKeyAccountManager } from '@/compositions/useKeyAccountManager';

import StandardSkeletonLoader from '@/components/skeleton-loader/StandardSkeletonLoader';

export default {
  name: 'ContactKeyAccountManagerModal',

  components: { BaseModal, StandardSkeletonLoader },

  setup(props, { emit }) {
    const { t: translation } = useI18n();

    function onClickCloseModal() {
      emit('on-click-close');
    }

    const { keyAccountManager, getKeyAccountManager, loading } =
      useKeyAccountManager();

    getKeyAccountManager();

    const kamName = computed(
      () =>
        `${keyAccountManager.value.firstName} ${keyAccountManager.value.lastName}`
    );
    const kamPhoneNumber = computed(() => keyAccountManager.value.phoneNumber);

    return {
      translation,
      loading,
      kamName,
      kamPhoneNumber,
      onClickCloseModal,
    };
  },
};
</script>
