import Api from '@/proxies/Api';
import Proxy from '@/proxies/Proxy';

import createUrl from '@/proxies/createUrl';

const { ACCOUNT } = Api;

class AccountProxy extends Proxy {
  getMe() {
    const url = createUrl({
      host: ACCOUNT.HOST,
      endpoint: { path: ACCOUNT.ME },
    });

    return this.get(url);
  }

  updateMe(me) {
    const url = createUrl({
      host: ACCOUNT.HOST,
      endpoint: { path: ACCOUNT.ME },
    });

    return this.put(url, me);
  }

  updateLanguage(languageCode) {
    const url = createUrl({
      host: ACCOUNT.HOST,
      endpoint: { path: ACCOUNT.LANGUAGE },
    });

    return this.put(url, languageCode);
  }
}

export default AccountProxy;
