/**
 * Enum string values.
 * @enum {string} TYPES
 */
export const TYPES = {
  INFO: 'information',
  WARNING: 'warning',
  ERROR: 'error',
};

export default { TYPES };
