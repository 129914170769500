<template>
  <div class="group relative w-max" @mouseover="calculatePopupPosition">
    <slot>
      <img src="@/assets/images/icons/icon-question.svg" alt="" />
    </slot>

    <span
      ref="popupRef"
      class="pointer-events-none invisible absolute z-10 m-2 block max-w-xss break-words rounded bg-white px-5 py-3 text-sm font-medium opacity-0 shadow transition-opacity group-hover:opacity-100"
      :style="`inline-size: max-content;`"
    >
      {{ content }}
    </span>

    <span
      class="pointer-events-none absolute z-10 m-2 block max-w-xss -translate-x-1/2 break-words rounded bg-white px-5 py-3 text-sm font-medium opacity-0 shadow transition-opacity group-hover:opacity-100"
      :style="`inline-size: max-content;
        ${top !== undefined ? `top: ${top}px;` : ''} 
        ${bottom !== undefined ? `bottom: ${bottom}px;` : ''} 
        ${left !== undefined ? `left: ${left}px;` : ''}`"
    >
      {{ content }}
    </span>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'Tooltip',

  props: {
    content: String,
  },

  setup() {
    const popupRef = ref(null);
    const top = ref(undefined);
    const bottom = ref(undefined);
    const left = ref(undefined);

    const calculatePopupPosition = () => {
      top.value = undefined;
      bottom.value = undefined;
      left.value = undefined;

      if (!popupRef.value) return;

      const popupBoundingClientRect = popupRef.value.getBoundingClientRect();
      const { top: popupTop, bottom: popupBottom } = popupBoundingClientRect;
      let { right: popupRight, left: popupLeft } = popupBoundingClientRect;

      // Adjust for position change caused by -translate-x-1/2
      // as well as margin not being calculated for width
      const margin = 8;
      const popupWidth = popupRight - popupLeft;
      popupRight = popupRight - popupWidth / 2 + margin;
      popupLeft = popupLeft - popupWidth / 2 - margin;

      const windowTop = 0;
      const windowRight = window.scrollX + window.innerWidth;
      const windowBottom = window.innerHeight;
      const windowLeft = window.scrollX;

      if (popupTop < windowTop) {
        top.value = windowTop;
      }

      if (popupRight > windowRight) {
        left.value = windowRight - popupRight;
      }

      if (popupBottom > windowBottom) {
        bottom.value = margin;
      }

      if (popupLeft < windowLeft) {
        left.value = windowLeft - popupLeft;
      }
    };

    return {
      popupRef,
      top,
      bottom,
      left,
      calculatePopupPosition,
    };
  },
};
</script>
