<template>
  <div class="relative flex-col" ref="root">
    <div
      v-if="showNavigation"
      class="grey-overlay fixed top-0 h-screen w-screen sm:hidden"
      @click="toggleNavigation"
    ></div>

    <img
      class="px-5"
      :src="require('@/assets/images/icons/' + menuIcon)"
      alt=""
      @click="toggleNavigation"
    />

    <transition name="dropdown-slide">
      <div v-if="showNavigation" class="absolute mt-2 w-screen sm:w-auto">
        <the-navigation class="mx-3" @click="toggleNavigation" />
      </div>
    </transition>
  </div>
</template>

<script>
import { computed, onMounted, onBeforeUnmount, ref } from 'vue';

import TheNavigation from '@/components/TheNavigation.vue';

import { useToggle } from '@/compositions/useToggle';

export default {
  name: 'TheHeaderHamburgerMenu',

  components: { TheNavigation },

  setup() {
    const root = ref(null);

    const { isVisible: showNavigation, toggleVisible: toggleNavigation } =
      useToggle();

    const menuIcon = computed(() =>
      showNavigation.value ? 'close-white-icon.svg' : 'hamburger-icon.svg'
    );

    function onClickOutside(e) {
      if (showNavigation.value && !root?.value?.contains(e.target)) {
        toggleNavigation();
      }
    }

    onMounted(() => {
      document.addEventListener('click', onClickOutside);
    });
    onBeforeUnmount(() => {
      document.removeEventListener('click', onClickOutside);
    });

    return {
      root,
      showNavigation,
      toggleNavigation,
      menuIcon,
    };
  },
};
</script>

<style scoped></style>
