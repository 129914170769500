<template>
  <div
    class="navigation rounded-md bg-white shadow-dropdown xl:rounded-none xl:shadow-none"
  >
    <div class="p-2 xl:mt-3">
      <div class="mb-6">
        <router-link :to="{ name: 'Home' }" v-slot="{ isActive }">
          <div
            class="navigation-link"
            :class="[isActive && 'router-link-active']"
          >
            {{ translation('general.navigation.home') }}
          </div>
        </router-link>
      </div>

      <div v-if="userHasAnyOfRoles(Roles.BLANCO, Roles.LEASING)" class="mb-6">
        <div class="section-title">
          {{ translation('general.navigation.sectionTitle.apply') }}
        </div>

        <router-link
          id="navigation-personal-loan"
          v-if="userHasAnyOfRoles(Roles.BLANCO)"
          :to="{ name: 'Blanco' }"
          v-slot="{ isActive }"
        >
          <div
            class="navigation-link"
            :class="[isActive && 'router-link-active']"
          >
            {{ translation('general.navigation.personalLoan') }}
          </div>
        </router-link>

        <router-link
          id="navigation-leasing"
          v-if="userHasAnyOfRoles(Roles.LEASING)"
          :to="{ name: 'Leasing' }"
          v-slot="{ isActive }"
        >
          <div
            class="navigation-link"
            :class="[isActive && 'router-link-active']"
          >
            {{ translation('general.navigation.leasing') }}
          </div>
        </router-link>
      </div>

      <div
        v-if="
          userHasAnyOfRoles(Roles.BUSINESS_FINANCE, Roles.POINT_SHOP) ||
          allowedToSeeBusinessFinanceOnboarding
        "
        class="mb-6"
      >
        <div class="section-title">
          {{ translation('general.navigation.sectionTitle.otherServices') }}
        </div>

        <router-link
          id="navigation-inventory-financing"
          v-if="userHasAnyOfRoles(Roles.BUSINESS_FINANCE)"
          :to="{ name: 'InventoryFinancing' }"
          v-slot="{ isActive }"
        >
          <div
            class="navigation-link"
            :class="[isActive && 'router-link-active']"
          >
            {{ translation('general.navigation.inventoryFinancing') }}
          </div>
        </router-link>
        <div
          v-else-if="allowedToSeeBusinessFinanceOnboarding"
          @click.stop
          id="navigation-inventory-financing-needs-onboarding"
        >
          <div
            @click="toggleKeyAccountManagerModal"
            class="navigation-link cursor-pointer"
          >
            {{ translation('general.navigation.inventoryFinancing') }}
          </div>
          <contact-key-account-manager-modal
            v-if="showContactKeyAccountManagerModal"
            :closeOnEscape="true"
            :closeOnClickOutside="true"
            @on-click-close="toggleKeyAccountManagerModal"
          />
        </div>

        <router-link
          id="navigation-point-shop"
          :to="{ name: 'PointShop' }"
          v-slot="{ isActive }"
        >
          <div
            v-if="userHasAnyOfRoles(Roles.POINT_SHOP)"
            class="navigation-link"
            :class="[isActive && 'router-link-active']"
          >
            {{ translation('general.navigation.pointShop') }}
          </div>
        </router-link>
      </div>

      <div
        v-if="
          userHasAnyOfRoles(
            Roles.BUSINESS_FINANCE,
            Roles.COMMISSION,
            Roles.ORGANIZATION_ADMIN
          )
        "
      >
        <div class="section-title">
          {{ translation('general.navigation.sectionTitle.administration') }}
        </div>

        <router-link
          id="navigation-report"
          v-if="userHasAnyOfRoles(Roles.BUSINESS_FINANCE)"
          to="/report"
          v-slot="{ isActive }"
        >
          <div
            class="navigation-link"
            :class="[isActive && 'router-link-active']"
          >
            {{ translation('general.navigation.report') }}
          </div>
        </router-link>

        <router-link
          id="navigation-commission"
          v-if="userHasAnyOfRoles(Roles.COMMISSION)"
          :to="{ name: 'Commission' }"
          v-slot="{ isActive }"
        >
          <div
            class="navigation-link"
            :class="[isActive && 'router-link-active']"
          >
            {{ translation('general.navigation.commission') }}
          </div>
        </router-link>

        <router-link
          id="navigation-organisation"
          v-if="userHasAnyOfRoles(Roles.ORGANIZATION_ADMIN)"
          :to="{ name: 'Organisation' }"
          v-slot="{ isActive }"
        >
          <div
            class="navigation-link"
            :class="[isActive && 'router-link-active']"
          >
            {{ translation('general.navigation.organisation') }}
          </div>
        </router-link>
      </div>
    </div>

    <div class="navigation-footer mt-1 flex justify-center xl:hidden">
      <img
        class="my-3"
        src="@/assets/images/primary-logotype.svg"
        alt="MyMoney"
      />
    </div>

    <contact-details class="text-black md:hidden" />
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import ContactDetails from '@/components/the-header/ContactDetails.vue';
import ContactKeyAccountManagerModal from '@/components/ContactKeyAccountManagerModal.vue';

import { useToggle } from '@/compositions/useToggle';

import Roles from '@/constants/Roles';

import Country from '@/locales/country';

export default {
  name: 'TheNavigation',

  components: { ContactDetails, ContactKeyAccountManagerModal },

  setup() {
    const { t: translation } = useI18n();
    const { getters } = useStore();
    const {
      isVisible: showContactKeyAccountManagerModal,
      toggleVisible: toggleKeyAccountManagerModal,
    } = useToggle();

    const userHasAnyOfRoles = getters['user/userHasAnyOfRoles'];

    const allowedToSeeBusinessFinanceOnboarding = [Country.SE.code].includes(
      process.env.VUE_APP_COUNTRY_CODE
    );

    return {
      translation,
      showContactKeyAccountManagerModal,
      toggleKeyAccountManagerModal,
      Roles,
      userHasAnyOfRoles,
      allowedToSeeBusinessFinanceOnboarding,
    };
  },
};
</script>

<style scoped>
.navigation {
  border: 1px solid rgb(233, 233, 233);
}

.section-title {
  @apply mb-1.5 px-2 font-bold uppercase tracking-wide;
  font-size: 0.688rem;
  color: #808080;
}

.navigation-link {
  @apply my-px rounded-md p-2 font-semibold;
  color: #263238;
  min-width: 12.5rem;
}

.navigation-link:not(.router-link-active):hover {
  background-color: #f2f2f2;
}

.router-link-active {
  color: #602e0c;
  background-color: #fedfca;
}

.navigation-footer {
  background-color: #f4f4f4;
}
</style>
