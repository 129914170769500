/* eslint-disable class-methods-use-this */
import { AxiosWithInterceptors } from '@/proxies/AxiosWithInterceptors';
import logOut from '@/compositions/logOut';

class BaseProxy {
  /**
   * The method used to perform an AJAX-request.
   *
   * @param {string}      requestType       The request type.
   * @param {string}      url               The URL for the request.
   * @param {Object|null} data              The data to be sent with the request.
   * @param {Object|null} headers           The header to be sent with the request.
   *
   * @returns {Promise} The result in a promise.
   */
  submit(requestType, url, data = null, headers = null) {
    return new Promise((resolve, reject) => {
      const requestConfig = {
        url,
        method: requestType,
        headers: {
          'access-control-allow-origin': '*',
          ...headers,
        },
      };
      if (data) {
        requestConfig.data = data;
      }

      if (requestConfig.headers.Accept === 'application/pdf') {
        requestConfig.responseType = 'arraybuffer';
      }

      AxiosWithInterceptors()
        .axios(requestConfig)
        .then((response) => {
          if (response.headers['content-type'] === 'application/pdf') {
            resolve(response);
          }

          resolve(response.data);
        })
        .catch((error) => {
          // Log user out as they are unauthorized
          const hasUnauthorizedError = error?.message === 'unauthorized';
          const hasInvalidGrantError = error?.error === 'invalid_grant';
          const hasUnauthorizedStatus = error?.response?.status === 401;

          const isUnauthorized =
            hasUnauthorizedError ||
            hasInvalidGrantError ||
            hasUnauthorizedStatus;

          if (isUnauthorized) {
            logOut();
          }
          reject(error.response);
        });
    });
  }

  /**
   * Method used to create an item.
   *
   * @param {string}  url     The URL for the request.
   * @param {Object}  item    The given item.
   *
   * @returns {Promise} The result in a promise.
   */
  post(url, item) {
    return this.submit('post', url, item);
  }

  /**
   * Method used to fetch items from the API.
   *
   * @param {string}  url     The URL for the request.
   *
   * @returns {Promise} The result in a promise.
   */
  get(url) {
    return this.submit('get', url);
  }

  /**
   * Method used to fetch binary data through GET
   *
   * @param {string}  url     The URL for the request.
   *
   * @returns {Promise}
   */
  getBlob(url) {
    return this.submit(
      'get',
      url,
      {},
      { Accept: 'application/pdf' },
      { responseType: 'arraybuffer' }
    );
  }

  /**
   * Method used to update an item.
   *
   * @param {string}  url     The URL for the request.
   * @param {Object}  item    The given item.
   *
   * @returns {Promise} The result in a promise.
   */
  put(url, item) {
    return this.submit('put', url, item);
  }

  /**
   * Method used to update an item.
   *
   * @param {string}  url     The URL for the request.
   * @param {Object}  item    The given item.
   *
   * @returns {Promise} The result in a promise.
   */
  patch(url, item) {
    return this.submit('patch', url, item);
  }

  /**
   * Method used to destroy an item.
   *
   * @param {string}  url     The URL for the request.
   *
   * @returns {Promise} The result in a promise.
   */
  delete(url) {
    return this.submit('delete', url);
  }

  /**
   * Temporary function to simulate API call
   *
   * @param data
   * @returns {Promise<unknown>}
   */
  simulateApiCall(data) {
    return new Promise((resolve) => {
      resolve(data);
    });
  }
}

export default BaseProxy;
