<template>
  <template-header class="header-apply text-message-gray">
    <template v-slot:left>
      <div class="header-apply-border mx-5 pr-5">
        <img
          class="h-8"
          src="@/assets/images/my-money-pay-positive.svg"
          alt="Pay"
        />
      </div>

      <div class="hidden text-lg sm:block">
        {{ title }}
      </div>
    </template>

    <template v-slot:right>
      <button
        class="flex items-center bg-secondary-accent p-5 font-semibold text-primary-accent"
        @click="onClickReturn"
      >
        <span class="mr-3">
          {{ translation('general.navigation.cancel') }}
        </span>
        <img src="@/assets/images/icons/close-green-icon.svg" alt="" />
      </button>
    </template>
  </template-header>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';

import TemplateHeader from '@/templates/TemplateHeader';

export default {
  name: 'TheHeaderApply',

  components: { TemplateHeader },

  setup() {
    const { t: translation } = useI18n();
    const router = useRouter();
    const route = useRoute();

    const title = route?.meta?.title ?? '';
    const backTarget = route?.meta?.backTarget;

    function onClickReturn() {
      if (backTarget) {
        router.push({ name: backTarget });
      } else {
        router.go(-1);
      }
    }

    return {
      translation,
      title,
      onClickReturn,
    };
  },
};
</script>

<style scoped>
.header-apply {
  box-shadow: 0 1px 0 0 #f3f3f3;
}

.header-apply-border {
  border-right: 1px solid rgba(126, 137, 137, 0.2);
}

@media print {
  .header-apply {
    @apply hidden;
  }
}
</style>
