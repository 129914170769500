<template>
  <div>
    <the-header-apply />

    <router-view
      class="m-auto min-h-screen max-w-2xl flex-auto flex-col px-5 py-24"
    />
  </div>
</template>

<style></style>
<script>
import TheHeaderApply from '@/components/the-header/TheHeaderApply';

export default {
  name: 'ApplyLayout',

  components: { TheHeaderApply },
};
</script>
