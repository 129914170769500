/* eslint no-shadow: ["error", { "allow": ["state"] }] */

// initial state
const state = () => ({
  isLoading: true,
});

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  resetLoading(state) {
    state.isLoading = true;
  },

  // This needs to be called on each component individually
  finishedLoading(state) {
    state.isLoading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
