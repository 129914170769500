<template>
  <label class="flex items-center" :class="{ toggle: isToggle }">
    <div class="checkbox-container items-center" :class="{ flex: !isToggle }">
      <input
        class="base-checkbox checkbox-button form-checkbox rounded transition-colors duration-300"
        :class="{ hidden: isToggle }"
        :style="{ color: checkboxColor }"
        :checked="shouldBeChecked"
        :disabled="disabled"
        :name="name"
        type="checkbox"
        :value="value"
        @change="updateInput"
      />

      <span
        class="slider block h-6 cursor-pointer overflow-hidden rounded-full border bg-primary-disabled shadow-inner transition-colors duration-200 ease-in"
        v-if="isToggle"
      />
    </div>

    <span
      v-if="hasDefaultSlot"
      class="base-checkbox-label transition-colors duration-300"
      :class="{ 'base-checkbox-label-disabled': disabled }"
    >
      <slot />
    </span>
  </label>
</template>

<script>
export default {
  name: 'BaseCheckbox',

  model: {
    prop: 'modelValue',
    event: 'update',
  },

  props: {
    disabled: Boolean,
    modelValue: {
      default: false,
    },
    name: String,
    type: {
      type: String,
      default: 'checkbox',
    },
    value: {
      default: true,
    },
    trueValue: {
      default: true,
    },
    falseValue: {
      default: false,
    },
    checkboxColor: {
      type: String,
      default: '#157a6f',
    },
  },

  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default;
    },

    shouldBeChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }

      return this.modelValue === this.trueValue;
    },
  },

  setup(props, { emit }) {
    const isToggle = props.type === 'toggle';
    async function updateInput(event) {
      const isChecked = event.target.checked;

      if (props.modelValue instanceof Array) {
        const newValue = [...props.modelValue];

        if (isChecked) {
          newValue.push(props.value);
        } else {
          newValue.splice(newValue.indexOf(props.value), 1);
        }

        await emit('update:modelValue', newValue);
        emit('on-change', newValue);
        emit('clicked-item', props.value);
      } else {
        const value = isChecked ? props.trueValue : props.falseValue;
        emit('update:modelValue', value);
        emit('on-change', value);
      }
    }

    return {
      updateInput,
      isToggle,
    };
  },
};
</script>

<style scoped>
.highlightError {
  @apply bg-red-200;
  @apply border-red-700;
}
.highlightError .base-checkbox-label {
  @apply text-red-700;
}

.base-checkbox[disabled] + .slider {
  @apply opacity-50;
}

.toggle .checkbox-container {
  @apply relative;
  @apply select-none;
  @apply w-12;
  @apply mr-2;
  @apply leading-normal;
}

.slider:before {
  @apply absolute;
  @apply block;
  @apply bg-white;
  @apply left-0;
  @apply right-auto;
  @apply w-5;
  @apply h-5;
  @apply border;
  @apply border-primary-disabled;
  @apply rounded-full;
  @apply mt-px;

  margin-left: 0.1rem;

  content: '';
  transition: all 0.2s ease-in;
}
.form-checkbox:checked + .slider {
  @apply bg-primary-accent;
  @apply shadow-none;
}
.form-checkbox:checked + .slider:before {
  left: 50%;
}
.checkbox-button {
  width: 1.125rem;
  height: 1.125rem;
}
</style>
