import replaceParameters from '@/utils/replaceParameters';

/**
 *
 * @param {Object} options
 * @param {string} options.host
 * @param {import('./Api')} options.endpoint
 * @param {Object} options.params
 * @param {Object} options.pathParams
 * @param {Object} options.queryParams
 * @param {string} [options.prefix]
 */

function addPrefixToHost(host, prefix) {
  const hasProtocol = host.startsWith('https:') || host.startsWith('http:');
  return `${hasProtocol ? '' : prefix}${host}`;
}

function appendPathToUrl(url, endpoint) {
  return endpoint.path ? `${url}/${endpoint.path}` : url;
}

function addQueryToUrl(url, endpoint, queryParams) {
  const queryString = new URLSearchParams(queryParams).toString();
  if (queryString || endpoint.query) {
    return (
      url + (url.includes('?') ? '&' : '?') + (queryString || endpoint.query)
    );
  }
  return url;
}

function createUrl({
  host,
  endpoint = {},
  params = {},
  pathParams = {},
  queryParams = {},
  prefix = 'https://',
}) {
  let url = addPrefixToHost(host, prefix);

  url = appendPathToUrl(url, endpoint);

  if (pathParams) {
    url = replaceParameters(url, pathParams);
  }

  url = addQueryToUrl(url, endpoint, queryParams);

  return replaceParameters(url, params);
}

export default createUrl;
