<template>
  <label>
    <input
      class="slider h-1.5 w-full"
      type="range"
      min="1"
      :max="max"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </label>
</template>

<script>
export default {
  name: 'BaseRangeSlider',

  props: {
    modelValue: [Number, String],
    max: [Number],
  },
};
</script>

<style scoped>
.slider {
  -webkit-appearance: none;
  background: #d8d8d8;
  outline: none;
}

.slider::-webkit-slider-thumb {
  @apply h-8 w-8 cursor-pointer appearance-none rounded-full bg-white;
  box-shadow: inset 0 0 0 9px #157a6f;
  -webkit-appearance: none;
}

.slider::-moz-range-thumb {
  @apply h-8 w-8 cursor-pointer rounded-full bg-white;
  box-shadow: inset 0 0 0 9px #157a6f;
}

.slider::-moz-range-progress {
  @apply bg-primary-accent;
}

.slider::-ms-fill-lower {
  @apply bg-primary-accent;
}
</style>
