export default {
  month: {
    month: 'long',
  },
  long: {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  },
};
