const APP_ID = process.env.VUE_APP_INTERCOM_APP_ID;
const SESSION_DURATION_IN_MILLIS = 60 * 60 * 1000;
let timeoutShutdown;

class Intercom {
  static prePopulate(content) {
    if (window.Intercom) {
      window.Intercom('showMessages');
      window.Intercom('showNewMessage', content);
    }
  }

  static open() {
    if (window.Intercom) {
      window.Intercom('show');
    }
  }

  static shutdown() {
    if (window.Intercom) {
      window.Intercom('shutdown');

      // Intercom sets localStorage even after a shutdown has
      // been done for some unknown reason - We'll wait a bit
      // and run another shutdown just in case.
      timeoutShutdown = setTimeout(() => {
        window.Intercom('shutdown');
      }, 7000);
    }
  }

  static boot(userId, userHash, languageCode) {
    // make sure we have window.Intercom loaded before initializing.
    if (window.Intercom) {
      clearTimeout(timeoutShutdown);

      const settings = {
        app_id: APP_ID,
        user_id: userId,
        user_hash: userHash,
        user_type: 'partnerweb',
        session_duration: SESSION_DURATION_IN_MILLIS,
        language_override: languageCode,
      };

      window.Intercom('boot', settings);
    } else {
      window.addEventListener('load', () => {
        this.boot(userId, userHash, languageCode);
      });
    }
  }
}

export default Intercom;
