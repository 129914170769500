<template>
  <div>
    <div class="mb-2 flex gap-1">
      <p
        v-if="label"
        class="base-input-label self-center font-semibold uppercase tracking-wide"
      >
        {{ label }}
      </p>
      <tooltip v-if="tooltipContent" :content="tooltipContent" />
    </div>

    <div class="relative">
      <input
        :id="inputId"
        class="base-input input-font-size w-full rounded border border-gray-400 px-3 leading-normal focus:shadow-md"
        :class="[inputSize, errorStyle]"
        :autocomplete="autocomplete"
        :min="min"
        :max="max"
        :name="name"
        :placeholder="placeholder"
        :type="inputType"
        :disabled="disabled"
        :pattern="pattern"
        :required="required"
        :value="value ?? modelValue"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
      />

      <div
        v-if="loading"
        class="absolute inset-y-0 right-0 flex items-center pr-3"
      >
        <img
          class="animate-spin"
          src="@/assets/images/loading-spinners/secondary-spinner.png"
          alt="loading"
        />
      </div>
      <div
        v-else-if="error"
        class="absolute inset-y-0 right-0 flex items-center pr-3"
      >
        <img src="@/assets/images/icons/error-icon.svg" alt="error" />
      </div>
      <div
        v-else-if="success"
        class="absolute inset-y-0 right-0 flex items-center pr-3"
      >
        <img src="@/assets/images/icons/checkmark-icon.svg" alt="success" />
      </div>
      <div
        v-else-if="isTypePassword"
        class="absolute inset-y-0 right-0 flex items-center pr-3"
      >
        <img
          v-if="isInputTypePassword"
          src="@/assets/images/icons/show-password-icon.svg"
          alt=""
          @click="showPassword"
        />
        <img
          v-else
          src="@/assets/images/icons/hide-password-icon.svg"
          alt=""
          @click="hidePassword"
        />
      </div>
    </div>

    <p
      v-if="error && errorHelperText"
      class="base-input-error-helper-text text-xs"
    >
      {{ errorHelperText }}
    </p>
    <p v-else-if="helperText" class="base-input-helper-text text-xs">
      {{ helperText }}
    </p>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import Tooltip from '@/components/tooltip/Tooltip';

export default {
  name: 'BaseInput',

  components: { Tooltip },

  props: {
    inputId: String,
    autocomplete: String,
    disabled: Boolean,
    error: Boolean,
    errorHelperText: String,
    helperText: String,
    label: String,
    loading: Boolean,
    min: Number,
    max: [Number, String],
    modelValue: [Number, String],
    value: [Number, String],
    name: String,
    pattern: String,
    placeholder: String,
    required: Boolean,
    size: {
      type: String,
      default: 'medium',
    },
    success: Boolean,
    type: {
      type: String,
      default: 'text',
    },
    tooltipContent: String,
  },

  setup(props, { emit }) {
    const inputType = ref(props.type);
    const isTypePassword = ref(props.type === 'password');
    const isInputTypePassword = computed(() => inputType.value === 'password');

    const inputSize = computed(() => {
      switch (props.size) {
        case 'large':
          return 'py-4';
        default:
          return 'py-2';
      }
    });

    const errorStyle = computed(() =>
      props.error
        ? 'base-input-error focus:ring-red-500'
        : 'focus:ring-green-600'
    );

    function showPassword() {
      inputType.value = 'text';
    }

    function hidePassword() {
      inputType.value = 'password';
    }

    function onInput(event) {
      emit('update:modelValue', event.target.value);
      emit('on-input', event.target.value);
    }

    function onFocus() {
      emit('on-focus');
    }

    function onBlur() {
      emit('on-blur');
    }

    return {
      inputType,
      isTypePassword,
      isInputTypePassword,
      inputSize,
      errorStyle,
      showPassword,
      hidePassword,
      onInput,
      onFocus,
      onBlur,
    };
  },
};
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  @apply m-0;
  -webkit-appearance: none;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.input-font-size {
  font-size: 100%;
}

input::--tw-ring-color {
  color: inherit;
}

input:focus {
  border-color: inherit;
}
</style>
