<template>
  <label class="flex cursor-pointer items-center">
    <input
      class="base-checkbox radio-button form-radio"
      :style="{ color: radioColor }"
      :checked="shouldBeChecked"
      :disabled="disabled"
      :name="name"
      type="radio"
      :value="value"
      @change="updateInput"
    />

    <span
      v-if="label"
      class="base-checkbox-label"
      :class="{ 'base-checkbox-label-disabled': disabled }"
    >
      {{ label }}
    </span>
  </label>
</template>

<script>
export default {
  name: 'BaseRadioButton',

  model: {
    prop: 'modelValue',
    event: 'update',
  },

  props: {
    disabled: Boolean,
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      default: '',
    },
    name: String,
    value: [String, Number],
    radioColor: {
      type: String,
      default: '#157a6f',
    },
  },

  computed: {
    shouldBeChecked() {
      return this.modelValue === this.value;
    },
  },

  methods: {
    updateInput() {
      this.$emit('update:modelValue', this.value);
    },
  },
};
</script>

<style scoped>
.radio-button {
  width: 1.125rem;
  height: 1.125rem;
}
</style>
