export default {
  general: {
    add: 'Lägg till',
    cancel: 'Avbryt',
    close: 'Stäng',
    contactKeyAccountManagerModal: {
      noAccess: 'Du har inte tillgång till den här produkten än.',
      kamName: 'Kontakta din säljare {kamName} för att få tillgång.',
    },
    copy: 'Kopiera',
    edit: 'Ändra',
    remove: 'Ta bort',
    save: 'Spara',
    saved: 'Sparat',
    search: 'Sök',
    startChat: 'Starta en chat',
    emptySearchResult:
      'Hittade ingen träff på din sökning. Nollställ sökningen och prova igen.',
    leads: {
      statuses: {
        MANUAL_INSPECTION: 'Under behandling',
        ACCOUNT_ANALYSIS: 'Komplettering',
        SUPPLEMENT: 'Komplettering behövs',
        SUPPLEMENT_CONTACT_US: 'Kontakta oss',
        SUPPLEMENT_ACTION: 'Komplettera',
        APPROVED: 'Godkänd',
        DENIED: 'Avslagen',
        SENT_TO_CUSTOMER: 'Inväntar signering',
        SIGNED: 'Avtal signerat',
        PAID_OUT: 'Utbetalt',
        EXPIRED: 'Utgången',
        ABORTED: 'Avbruten',
        PROCESSING: 'Under behandling',
        FINISHED: 'Avslutad',
      },
    },
    navigation: {
      back: 'Tillbaka',
      sectionTitle: {
        apply: 'Ansök',
        otherServices: 'Övriga tjänster',
        administration: 'Administration',
      },
      personalLoan: 'Privatlån',
      leasing: 'Leasing',
      inventoryFinancing: 'Lagerfinansiering',
      market: 'Market',
      pointShop: 'Poängshop',
      priceTag: 'Prisskylt',
      report: 'Rapporter',
      commission: 'Provision',
      organisation: 'Organisation',
      myProfile: 'Min profil',
      logOut: 'Logga ut',
      loanApplication: 'Låneansökan',
      cancel: 'Avbryt',
      home: 'Startsida',
    },
    pageTitle: {
      titlePrefix: 'MyMoney Pay',
      activateAccount: 'Aktivera konto',
      home: 'Startsida',
      blancoList: 'Låneansökningar',
      blancoDetails: 'Lånedetalj',
      blancoApply: 'Låneansökan',
      leasingList: 'Leasing',
      leasingDetails: 'Leasingdetalj',
      leasingApply: 'Leasingansökan',
      inventoryFinancingList: 'Lagerfinans',
      inventoryFinancingDetails: 'Lagerfinansansökan',
      inventoryFinancingPayment: 'Lagerfinansinbetalning',
      inventoryFinancingApply: 'Lagerfinansieringsansökan',
      market: 'Market',
      marketDetail: 'Market objekt',
      marketConfirm: 'Bekräfta köp',
      pointShopList: 'Poäng-shop',
      pointShopArticle: 'Artikel',
      pointShopCheckout: 'Checkout',
      priceTag: 'Prisskylt',
      report: 'Rapporter',
      commission: 'Provision',
      organisation: 'Organisation',
      user: 'Användare',
      userMyProfile: 'Min profil',
      maintenance: 'Underhåll pågår',
    },
    company: 'Företag',
    email: 'E-postadress',
    date: 'Datum',
    mobilePhoneNumber: 'Mobilnummer',
    phone: 'Telefon',
    sortingFilterLabel: 'Sortera efter',
    sortingFilter: {
      createdTimeDesc: 'Nyast först',
      latestAsc: 'Äldst',
      latestDesc: 'Senaste',
      priceAsc: 'Billigast',
      priceDesc: 'Dyrast',
    },
    imageCarousel: {
      imageCounter: 'Bild {current} av {total}',
    },
    genericError: 'Något gick fel',
    technicalError:
      'Tekniskt fel, vänligen kontakta MyMoney på telefon 0770-176070.',
    workingDays: 'Vardag:',
    saturday: 'Lördag:',
    sunday: 'Söndag:',
    openingHourClosed: 'Stängt',
    download: 'Ladda ner',
    pointsEarned: '{pointsEarned} poäng',
  },
  'activate-account': {
    chooseAPassword: 'Välj ett lösenord',
    passwordRequirements:
      'Lösenordet måste bestå av minst 8 tecken, innehålla minst en liten (a-z) och en stor bokstav (A-Z) samt minst en siffra (0-9).',
    yourPassword: 'Ditt lösenord',
    confirmPassword: 'Bekräfta lösenord',
    passwordMismatch: 'Lösenorden är inte likadana',
    passwordNotLongEnough: 'Det nya lösenordet är inte långt nog.',
    passwordDoNotContainLowercaseLetter:
      'Det nya lösenordet måste innehålla minst en liten bokstav: a-z.',
    passwordDoNotContainUppercaseLetter:
      'Det nya lösenordet måste innehålla minst en stor bokstav: A-Z.',
    passwordDoNotContainAnyNumbers:
      'Det nya lösenordet måste innehålla minst en siffra: 0-9.',
    savePassword: 'Spara lösenord',
    passwordError:
      'Lösenordet uppfyller inte säkerhetskraven ovan, vänligen välj ett annat lösenord.',
    resetTokenError:
      'Denna länk för att aktivera ditt konto är för gammal eller redan använd. Prova att logga in på Pay eller kontakta oss.',
  },
  home: {
    greetings: 'Hej {user}',
    searchPlaceholder: 'Sök på namn eller personnummer',
    myLastApplications: 'Mina senaste ansökningar',
    showMyApplications: 'Visa alla mina ansökningar',
    priceSign: {
      title: 'Prisskylt',
      body: 'Skapa en utskriftsvänlig prisskylt som visar den låga månadskostnaden',
    },
    emptySearch: 'Hittade ingen sökträff som matchar “{search}”',
    showAllVehicles: 'Visa alla fordon',
    latestAddedVehicles: 'Senaste tillagda fordon',
    market: 'Market',
    pointShop: 'Poängshoppen',
    latestReceived: 'Senaste inkomna',
    showAllProducts: 'Visa alla produkter i shoppen',
    youHaveXXXPoints: {
      beforePoints: 'Du har',
      afterPoints: 'att handla för',
    },
  },
  loan: {
    searchResultsMatching:
      '{totalElements} sökträffar som matchar "{searchTerm}"',
    resetSearch: 'Rensa sökning',
    resetFilter: 'Nollställ filter',
    personFilterLabel: 'Visar',
    personFilterDefault: 'Allas',
    personFilterOnlyMine: 'Endast mina',
    facilityFilterLabel: 'Anläggning',
    facilityFilterDefault: 'Alla',
    statusFilterLabel: 'Status',
    statusFilterDefault: 'Alla',
    firstApplication: 'Kom igång med din första ansökan!',
    listLoadingError: {
      somethingWentWrong: 'Något gick fel!',
      tryAgainLater:
        'Vi lyckades inte att hämta dina ansökningar just nu. Vänligen prova igen om en stund.',
      retry: 'Prova igen',
    },
    status: 'Status',
    secondChance: {
      title: 'En andra chans att få ansökan godkänd!',
      description:
        'Genom att låta oss vidarebefordra ansökan kan vi erbjuda en extra möjlighet för kunden att få sin låneansökan godkänd. Kunden gör detta snabbt och enkelt direkt på sin telefon.',
      step1: 'Kunden får email och SMS, följer länken och loggar in med BankID',
      step2: 'Kunden väljer ett låneerbjudande och får pengarna själv',
      step3: 'Ni kontaktar kunden och slutför affären',
      buttonText: 'Vidarebefordra nu!',
      processTitle: 'Andra chansen: Behandlas just nu',
      processTextPart1: 'Tack för att du använder Andra chansen.',
      processTextPart2: 'Kundens ansökan behandlas just nu.',
      processTextPart3:
        'Tänk på att det kan ta upp till 15 minuter innan kunden får email och SMS.',
      processTextPart4:
        'Om du har några frågor, kontakta {0} eller 0770-17 60 70.',
      email: "partner{'@'}mymoney.se",
    },
    reference: 'Referens',
    referenceId: 'Referens ID',
    contractSigned: 'Avtal signerat',
    amount: 'Belopp',
    createdTime: 'Ansökningsdatum',
    desiredInterestRate: 'Önskad ränta',
    interestFree: 'Räntefritt',
    applicationIsProcessing:
      'Vi jobbar stenhårt på att hitta en lösning och återkommer till dig så fort vi kan.',
    returnBackToArchive: 'Tillbaks till startsidan',
    goToLead: 'Gå till ansökan',
    makeNewApplication: 'Gör en ny ansökan',
    otherTerms: 'Övriga villkor',
    iAcceptTerms: 'Jag accepterar användarvillkoren.',
    informedAboutCreditCheck:
      'Kunden är informerad om att en kreditupplysning tas.',
    splitYourPayment: 'Dela upp din betalning från',
    costPerMonth: '{monthlyPayment}/mån',
    interest: 'Ränta',
    loanType: 'Typ av ansökan',
    sendContractTitle: 'Finansiering av vara',
    sendContractFirstMessage:
      'Detta lån får endast användas till att finansiera slutkundens köp av en vara från ert företag. Om köpet, oavsett anledning skulle gå tillbaka skall ni alltid återbetala lånebeloppet till MyMoney.',
    sendContractSecondMessage:
      'Observera att ni aldrig får betala ut hela eller delar av detta lån direkt till slutkunden.',
    sendContractApprove: 'Jag bekräftar och godkänner',
    signingLink: 'Signeringslänk',
    sendAgreement: 'Skicka avtal',
    terms: {
      SE: {
        general: {
          text: 'Jag godkänner {0} och försäkrar att jag som användare följer de riktlinjer som lagstadgas i {1}',
          link1: 'användarvillkoren för MyMoney Pay',
          link2: 'KKrL (2010:1846)',
        },
        businessAreaTerms: {
          MEDICAL:
            'Samtycke är inhämtat från Kund om att uppgifter om behandlingen och kundens personuppgifter utlämnas till MyMoney i den omfattning som är nödvändig för bedömning av kreditansökan samt för handläggning av potentiella tvister och för stickprovskontroller.',
        },
      },
      FI: {
        general: {
          text: 'Jag godkänner {0} för MyMoney Pay',
          link: 'användarvillkoren',
        },
        asiakastieto: {
          text: 'Jag ger mitt samtycke till överföring av information till kreditgivare i enlighet med det undersökningssystem som upprätthålls av Suomen {0}.',
          link: 'Asiakastieto Oy',
        },
      },
    },
  },
  loanDetails: {
    application: 'Ansökan',
    payments: 'Betalningar',
    documents: 'Dokument',
  },
  loanTermsModal: {
    modalTitle: 'Användarvillkor Pay',
    aboutService: {
      title: 'Om tjänsten',
      text: 'I MyMoney Pay (nedan kallad ”Tjänsten”) kan anslutna samarbetspartners (nedan kallade ”Återförsäljare”) åt sina slutkunder ansöka om lån för att finansiera köp av vara eller tjänst. Personer anställda hos Återförsäljare (nedan kallade”Användare”) får som representant för Återförsäljare tillgång till Tjänsten genom ett användarkonto. Genom att använda tjänsten godkänner och omfattas Återförsäljare och Användare av dessa Användarvillkor. Tjänsten tillhandahålls av MyMoney Europe AB,556702-1679.',
    },
    gdpr: {
      title: 'GDPR',
      text: 'Den 25 maj 2018 började ett nytt EU-gemensamt regelverk, GDPR, General Data Protection Regulation, att gälla. Syftet med GDPR är att skydda fysiska personer genom att reglera hur personuppgifter får behandlas.',
    },
    handlingOfPersonalData: {
      title: 'Hantering av personuppgifter',
      text: 'Personuppgifter registreras i samband med skapande av användarkonto i Tjänsten. Personuppgifterna behandlas av MyMoney och andra företag som MyMoney samarbetar med för hantering och tillhandahållande av Tjänsten. Behandling av uppgifter sker också för att MyMoney skall kunna fullgöra sina förpliktelser enligt lag. Personuppgifterna kan även ligga till grund för marknadsanalyser, marknadsföring och riskhantering. Ytterligare information samt dina rättigheter kan du läsa mer om på',
      link: 'dataskyddspolicy',
      endText: 'där du finner MyMoney’s fullständiga dataskyddspolicy.',
    },
    creditInformation: {
      title: 'Kreditupplysning',
      text: 'MyMoney AB med organisationsnummer 556702-1679 är personuppgiftsansvarig för de uppgifter som du lämnar.',
    },
    personalDataController: {
      title: 'Personuppgiftsansvarig',
      text: 'Återförsäljare skall informera den eller de som ansökan avser att MyMoney kan komma att inhämta kreditupplysning, antingen själva eller via en eller flera partners. Kreditupplysning kan inhämtas via Syna AB, UC AB, Businesscheck AB eller Soliditet AB.',
    },
    idControl: {
      title: 'IDkontroll',
      text: 'Återförsäljare skall alltid innan ansökan görs genomföra ID-kontroll med godkänd, av svensk myndighet utgiven ID-handling på den eller de personer som ansökan avser.',
    },
    pointShop: {
      title: 'Poängshop',
      text: 'Återförsäljare har möjlighet att delta i MyMoneys poängshop där erhållna poäng kan omvandlas till premier. Alla erhållna poäng och premier tillfaller Återförsäljaren oavsett vem hos Återförsäljaren som skickat in poänggrundande ansökning. Återförsäljare ansvarar för betalning av eventuella skatter och avgifter som kan uppkomma i samband med deltagande i poängshoppen.',
    },
    acceptTermsButtonText: 'Acceptera villkor',
  },
  blanco: {
    pageHeadline: 'Privatlån',
    applyForLoan: 'Ansök om privatlån',
    searchPlaceholder: 'Sök på referens, sökande eller personnummer',
    searchPaginationInfo:
      'Visar {paginationFirstItemNumber} - {paginationLastItemNumber} av totalt {totalElements} privatlån',

    returnBackToBlanco: 'Tillbaka till privatlån',
    loanApplication: 'Låneansökan',
    signingLinkCopied: 'Signeringslänken kopierad!',
    contractSentToCustomer:
      'Vi har skickat ett sms till kunden med instruktioner och väntar på att skuldebrevet ska bli signerat.',
    loanAmount: 'Lånebelopp',
    interestRate: '{interestRate} ränta',
    monthlyPayment: 'Månadskostnad',
    month: '{month} mån',
    hideMonthlyCostPerYear: 'Dölj månadskostnad vid annan återbetalningstid',
    showMonthlyCostPerYear: 'Visa månadskostnad vid annan återbetalningstid',
    applicant: 'Sökande',
    coApplicant: 'Medsökande',
    personalId: 'Personnummer',
    other: 'Övrigt',
    myInvoiceReference: 'Egen fakturareferens',
    invoiceReferenceHelperText: 'Max 20 tecken, endast bokstäver & siffror',
    noLeads: 'Inga låneansökningar',
    noLeadsYet: 'Du har inte ansökt om några privatlån ännu.',
    additionalInformation: 'Kompletterande uppgifter',
    enterLoanAmount: 'Ange lånebelopp',
    commissionsEarned: '{commissionsEarned} provision',
    maxCreditLimitExceeded:
      'Du har angett ett för högt belopp, högsta tillåtna är {maxCreditLimit} - Välj en annan ränta.',
    maxCreditLimitExceededForAllProducts:
      'Du har angett ett för högt belopp, högsta tillåtna är {maxCreditLimit}',
    minCreditLimitExceeded:
      'Du har angett ett för lågt belopp, minsta tillåtna är {minCreditLimit}',
    lowestMonthlyCostSummary: 'Lägsta månadskostnad',
    costPerMonth: 'kr / mån',
    payOut: 'Utbetalas',
    payOutSum: '{minPayoutQuota} kr',
    repaymentYears: '{years} års avbetalningstid',
    repaymentMonths: '{months} månaders avbetalningstid',
    repaymentOptions: 'Avbetalningsalternativ',
    addCoApplicant: 'Lägg till medsökande',
    hideTerms: 'Dölj villkor',
    loanInterestCards: {
      title: 'Önskad månadskostnad',
      loading: 'Ansöker om erbjudanden',
      currencyPerMonth: 'kr/mån',
    },
    repaymentOptionsModal: {
      calculateMonthlyCostByRepaymentMonths:
        'Räkna fram inbetalningskostnad för snabbare avbetalning av lånet',
      repaymentTime: 'Önskad återbetalningstid',
      month: '{month} mån',
      dueMonthly: 'Inbetalas varje månad',
      dueMonthlyExplanation:
        'Detta är en ungefärlig beräkning av summan som din kund bör betala in varje månad att betala av lånet på kortare tid. Beloppet på fakturan kommer dock fortsatt att vara kundens lägsta möjliga månadskostnad att betala. Eventuella avi- och uppläggningsavgifter har inte tagits hänsyn till i beräkningen',
      closeModal: 'Stäng avbetalningsalternativ',
    },
    priceTag: {
      header: 'Rubrik',
      headerPlaceholder: 'Fyll i rubrik för prisskylt. Ex. Volvo V60 D4',
      downloadPriceTag: 'Ladda ner prisskylt (PDF)',
    },
    applicantForm: {
      personalIdPlaceholder: 'ÅÅÅÅMMDDXXXX',
      phoneNumberPlaceholder: '07XX XXX XXX',
      sourceOfIncome: 'Sysselsättning',
      sourceOfIncomePlaceholder: 'Välj sysselsättning',
      income: 'Månadsinkomst',
      childrenUnder18: 'Barn under 18 år',
      childrenUnder18Placeholder: 'Antal barn under 18 år',
      children: {
        noChildren: 'Inga barn',
        oneChild: '1 barn',
        multipleChildren: '{children} barn',
      },
      sourceOfIncomes: {
        permanentEmployment: 'Fast anställning',
        selfEmployed: 'Egenföretagare',
        temporaryEmployment: 'Tidsbegränsad anställning',
        retired: 'Pensionär',
      },
      personalIdNotFound: 'Hittade ingen person med det personnummret',
      tooltips: {
        income: 'Din månadsinkomst före skatt.',
      },
    },
    detailedForm: {
      employerName: 'Arbetsgivare',
      employmentStartedTime: 'Anställningsdatum',
      employmentStartedTimePlaceholder: 'ÅÅÅÅ-MM-DD',
      maritalStatus: 'Civilstånd',
      maritalStatusPlaceholder: 'Välj civilstånd',
      maritalStatuses: {
        single: 'Ensamstående',
        married: 'Gift',
        cohabiting: 'Sambo',
        divorced: 'Skild',
        widow: 'Änka / Änkling',
      },
      typeOfResidence: 'Boendeform',
      typeOfResidencePlaceholder: 'Välj boendeform',
      typeOfResidences: {
        rental: 'Hyresrätt',
        condo: 'Bostadsrätt',
        sublet: 'Andrahand',
        villa: 'Villa',
      },
      livingExpenses: 'Boendekostnad per månad',
      loanExpenses: 'Lånekostnad per månad',
      otherExpenses: 'Övriga fasta utgifter per månad',
      tooltips: {
        livingExpenses: 'Hyra/avgift och el. Inga bolånerelaterade kostnader.',
        loanExpenses:
          'Månatliga amorteringar på alla lån, inklusive den månatliga amorteringen på hypotekslånet.',
      },
    },
    sendLead: 'Skicka låneansökan',
    applyResponses: {
      applicationAddOwnReference:
        'Om du vill ange en egen referens på fakturan kan du göra det här 👇',
      applicationIsPollingTitle: 'Din låneansökan är inskickad!',
      applicationIsPolling:
        'Håll i dig ett par sekunder medan systemet kontaktar alla kreditgivare',
      applicationIsProcessingTitle: 'Din ansökan är under behandling.',
      applicationIsProcessing:
        'Vi jobbar stenhårt på att hitta en lösning och återkommer till dig så fort vi kan.',
      accountAnalysis: {
        title: 'Möjlighet att få ansökan beviljad!',
        description1:
          'Genom att låta oss göra en kompletterande analys av kundens lönekonto kan vi erbjuda en extra möjlighet för kunden att få sin låneansökan beviljad.',

        description2:
          'Detta gör kunden snabbt och enkelt direkt i sin telefon.',
        bullet1:
          'Ett SMS med en länk har redan skickats till kunden på: {phoneNumber}',
        bullet2:
          'Kunden öppnar länken och ger sitt medgivande med hjälp av BankID',
        bullet3: 'Ni får svar inom kort om lånet har beviljats',
      },
      applicationIsApprovedTitle: 'Din ansökan är godkänd!',
      applicationIsApproved:
        'Succé, gå till ansökan för att kontrollera villkoren och skicka ut avtalet till din kund.',
      applicationIsRejectedTitle: 'Din ansökan är avslagen.',
      applicationIsRejected:
        'Vi är ledsna men vi kan inte hjälpa till med just denna ansökan. Slå oss en signal eller skriv i chatten om du vill ha mer information.',
    },
    buyerAddress: 'Postadress',
    supplement: {
      supplementReasonTitle:
        'Ansökan behöver kompletteras för att kunna godkännas',
      subtitle:
        'Din ansökan är nästan klar! \n' +
        'Vi behöver bara lite mer information för att kunna gå vidare med den',
      actionComplement:
        'Komplettera ansökan genom att skicka följande information till oss',
      actionContactUs: 'Kontakta oss så hjälper vi dig',
      coApplicantInformationPrompt:
        'Vi behöver veta följande om den medsökande',
      income: 'Inkomst',
      certificateOfEmployment: 'Anställningsavtal för ',
      incomeVerification: 'De tre senaste lönespecifikationerna för ',
      personalId: 'Personnummer',
      mobilePhoneNumber: 'Mobilnummer',
      sourceOfIncome: 'Sysselsättning',
      childrenUnder18: 'Antal barn under 18 år',
      startChat: 'Starta en chatt',
      prePopulatedChatContent:
        'Hej här kommer komplettering för {firstName} {lastName} {personalId}',
    },
    samblaApplicationModal: {
      sendApplicationApprove: 'Jag bekräftar och godkänner',
      userTerms: 'villkoren',
      sendApplicationEmailRequired:
        'För att kunna skicka vidare ansökan så vi behöver kundens emailadress.',
      close: 'Stäng',
      send: 'Skicka vidare nu!',
      later: 'Gör det senare',
      responseInformation: 'Kunden kommer strax att få SMS med instruktioner.',
      contact: {
        text: 'Kontakta {0} eller 0770-17 60 70 om du behöver hjälp.',
        email: "partner{'@'}mymoney.se",
      },
      email: 'Email',
      sendApplicationTitle: 'En andra chans att få ansökan godkänd!',
      responseApplicationTitle: 'Vi har tagit emot din ansökan!',
    },

    error: {
      title: {
        apply: {
          noAccess: 'Vi kunde inte hitta låneansökan!',
          notFound: 'Vi kunde inte hitta låneansökan!',
          wrongData: 'Något gick fel',
        },
        invoiceReference: {
          noAccess: 'Något gick fel',
          notFound: 'Något gick fel',
          wrongData: 'Felaktig referens',
        },
        detail: {
          noAccess: 'Vi kunde inte hitta låneansökan!',
          notFound: 'Vi kunde inte hitta låneansökan!',
          wrongData: 'Något gick fel',
        },
        monthlyPayment: {
          noAccess: 'Månadskostnaden går inte att räkna ut!',
          notFound: 'Månadskostnaden går inte att räkna ut!',
          wrongData: 'Månadskostnaden går inte att räkna ut',
        },
        dailyPayment: {
          noAccess: 'Dagkostnaden går inte att räkna ut!',
          notFound: 'Dagkostnaden går inte att räkna ut!',
          wrongData: 'Dagkostnaden går inte att räkna ut',
        },
        priceList: {
          noAccess: 'Vi kunde inte hitta prislistan!',
          notFound: 'Vi kunde inte hitta prislistan!',
        },
        priceTag: {
          noAccess: 'Vi kunde inte ladda ner PDF!',
          notFound: 'Vi kunde inte ladda ner PDF!',
          wrongData: 'Vi kunde inte ladda ner PDF!',
        },
        sendContract: {
          noAccess: 'Något gick fel',
          notFound: 'Något gick fel',
          wrongData: 'Något gick fel',
        },
        creditProducts: {
          noAccess: 'Något gick fel',
          notFound: 'Något gick fel',
          wrongData: 'Något gick fel',
        },
        checkout: {
          negativeBalance: 'För lite poäng',
        },
      },
      message: {
        apply: {
          noAccess: 'Låneansökan är inte tillgänglig.',
          notFound: 'Låneansökan är inte tillgänglig.',
          wrongData: 'Något gick fel',
        },
        invoiceReference: {
          noAccess: 'Låneansökan är inte tillgänglig.',
          notFound: 'Låneansökan är inte tillgänglig.',
          wrongData:
            'Referensen får endast innehålla bokstäver och siffror (inga mellanslag eller bindestreck). Max 8 tecken.',
        },
        detail: {
          noAccess: 'Låneansökan är inte tillgänglig.',
          notFound: 'Låneansökan är inte tillgänglig.',
          wrongData: 'Något gick fel',
        },
        monthlyPayment: {
          noAccess: 'Det går inte att komma åt beräkningen.',
          notFound: 'Det går inte att komma åt beräkningen.',
          wrongData: 'Felaktig data',
        },
        dailyPayment: {
          noAccess: 'Det går inte att komma åt beräkningen.',
          notFound: 'Det går inte att komma åt beräkningen.',
          wrongData: 'Felaktig data',
        },
        priceList: {
          noAccess: 'Prislistan är inte tillgänglig.',
          notFound: 'Prislistan är inte tillgänglig.',
        },
        priceTag: {
          noAccess: 'Prisskylt är inte tillgänglig.',
          notFound: 'Prisskylt är inte tillgänglig.',
          wrongData: 'Något gick fel',
        },
        sendContract: {
          noAccess: 'Det går inte att skicka avtal',
          notFound: 'Det går inte att skicka avtal',
          wrongData: 'Det går inte att skicka avtal',
        },
        creditProducts: {
          noAccess: 'Det går inte att komma åt beräkningen.',
          notFound: 'Det går inte att komma åt beräkningen.',
          wrongData: 'Felaktig data',
        },
        checkout: {
          negativeBalance:
            'Du har överskridit värdet av dina tillgängliga poäng, vänligen justera din varukorg.',
        },
      },
    },
  },
  leasing: {
    pageHeadline: 'Leasing',
    applyForLeasing: 'Ansök om leasing',
    searchPlaceholder: 'Sök på företagsnamn eller registreringsnummer',
    searchPaginationInfo:
      'Visar {paginationFirstItemNumber} - {paginationLastItemNumber} av totalt {totalElements} leasingansökningar',
    backToLeasing: 'Tillbaka till Leasing',
    noLeads: 'Inga leasingansökningar',
    noLeadsYet: 'Du har inte ansökt om någon leasing ännu.',
    leasingRent: 'Leasinghyra',
    costPerMonthExclVAT: 'kr/mån (exkl. moms)',
    helperTextsPercent: 'Motsvarar {percent} av priset',
    labels: {
      application: 'Leasingansökan',
      financing: 'Finansiering',
      customerInformation: 'Kundinformation',
      object: 'Objekt',
      priceExclVAT: 'Pris (exkl. moms)',
      downPaymentExclVAT: 'Första förhöjd hyra (exkl. moms)',
      repaymentPeriod: 'Leasingperiod',
      residualValueExclVAT: 'Restvärde (exkl. moms)',
      leasingRentExclVAT: 'Leasinghyra (exkl. moms)',
      organizationNumber: 'Organisationsnummer',
      contactPerson: 'Kontaktperson',
      phoneNumber: 'Telefonnummer',
      emailAddress: 'E-postadress',
      brand: 'Märke',
      model: 'Modell',
      yearModel: 'Årsmodell',
      numberPlate: 'Registreringsnummer',
      distanceDriven: 'Mätarställning',
      monthlyPaymentPerMonth: '{monthlyPayment}/månad',
      sendApplication: 'Skicka leasingansökan',
      acceptTerms: 'Acceptera användarvillkoren',
    },
    placeholders: {
      repaymentPeriod: 'Välj leasingperiod',
      organizationNumber: 'XXXXXXXXXX',
      brand: 'Ange märke',
      model: 'Ange modell',
      yearModel: 'Ange årsmodell',
      numberPlate: 'ABC123',
      distanceDriven: '0 mil',
      phoneNumber: '07XX XXX XXX',
    },
    fixFormErrors: 'Korrigera felaktiga värden i formuläret',
    formErrors: {
      Min: 'Måste vara minst {value}',
      Length: 'Längden matchar inte {value}',
      NotEmpty: 'Får ej vara tom',
      Email: 'Ej giltig email-adress',
      TooHigh: 'Får ej vara högre än priset',
      Pattern: 'Felaktigt värde',
    },
    values: {
      months: '{months} månader',
    },
    applicationSuccessfullySent: 'Din ansökan är under behandling.',
    missingOrganization:
      'Kunde inte hitta någon organisation med detta organisationsnumret',
  },
  'inventory-financing': {
    pageHeadline: 'Lagerfinansiering',
    applyForInventoryFinancing: 'Ny ansökan',
    searchPlaceholder: 'Sök på registreringsnummer, märke eller modell',
    searchPaginationInfo:
      'Visar {paginationFirstItemNumber} - {paginationLastItemNumber} av totalt {totalElements} lagerfinansieringsansökningar',
    noLeads: 'Inga lagerfinansansökningar',
    noLeadsYet: 'Du har inte ansökt om någon lagerfinans ännu.',
    returnToInventoryFinancing: 'Tillbaka till lagerfinansiering',
    returnToDetails: 'Tillbaka till ansökan',
    repaymentDays: 'Max {days} dagars avbetalningstid',
    payAbout: 'Du betalar ca {amount} per dag',
    fixFormErrors: 'Korrigera felaktiga värden i formuläret',
    applicationSuccessfullySent: 'Din ansökan är under behandling.',
    overview: 'Lagerfinansöversikt',
    document: 'Dokument',
    contractSentToCustomer:
      'Vi har skickat signeringslänken till dig via SMS och väntar på att avtalet skall signeras',
    applicationObservation:
      'OBS! - Tänk på att ange den person eller det företag som säljer bilen till er. Dvs inte ert eget företag.',
    notOnboardedPartner:
      'Lagerfinansprodukten är inte aktiverad för dig än. Kontakta oss så hjälper vi dig.',
    applicationConfirm: {
      loadingTitle: 'Din ansökan är inskickad!',
      loadingBody: 'Håll i dig ett par sekunder medan systemet jobbar',
      approvedTitle: 'Din ansökan är godkänd!',
      approvedBody:
        'Succé, gå till ansökan för att kontrollera villkoren och signera avtalet.',
    },
    limit: {
      status: 'Limit status',
      activeCars: 'Aktiva bilar',
      remaining: 'Kvarstående',
      total: 'Totalt',
      contact: 'Kontakta oss',
    },
    labels: {
      object: 'Objekt',
      maxCreditDays: 'Lagerdagar',
      creditAmount: 'Kreditbelopp',
      application: 'Ansökan',
      numberPlate: 'Registreringsnummer',
      brand: 'Märke',
      model: 'Modell',
      yearModel: 'Årsmodell',
      objectVatStatus: 'Momsstatus',
      seller: 'Säljare',
      financing: 'Finansiering',
      price: 'Pris',
      firstInstallment: 'Första delbetalning',
      appliedInterest: 'Månadsränta',
      agreementFee: 'Uppläggningsavgift',
      signing: 'Signering',
      authorizedSignatory: 'Firmatecknare',
      guarantor: 'Borgensman',
      missingVehicleInformation:
        'Obs! Fordonsinformation saknas, kontrollera uppgifterna och tryck på “Hämta fordon”',
      errorSameOrganizationNumber:
        'Ni kan inte ange ert eget org.nr, välj annan säljare.',
      sendApplication: 'Skicka lagerfinansansökan',
      privateSeller: 'Privatperson',
      companyContactPerson: 'Kontaktperson',
      organisationNumber: 'Organisationsnummer (10 siffror)',
      sellerInfo: 'Säljare',
      privateSellerName: 'Namn',
      personalId: 'Personnummer',
      buyer: 'Köpare',
      guarantorPersonalId: 'Borgensman personnummer',
      guarantorPhoneNr: 'Borgensman mobilnummer',
      authorizedSignatoryPersonalId: 'Firmatecknare personnummer',
      authorizedSignatoryPhoneNr: 'Firmatecknare mobilnummer',
      samePersonPersonalId: 'Firmatecknare/Borgensman personnummer',
      samePersonPhoneNr: 'Firmatecknare/Borgensman mobilnummer',
      priceCurrency: 'Valuta',
      downPayment: 'Kontantinsats (minst {percentage}% av fordonets pris)',
      vehicle: 'Fordon',
      vehiclePrice: 'Fordonets pris',
      includesVAT: '(inkl. moms)',
      guarantorSameAsAuthSign: 'Borgensman skall vara samma som firmatecknare',
      iAcceptTerms: 'Jag godkänner',
      termsText: 'användarvilkoren',
      documentName: 'Dokumentnamn',
      noDocumentsToShow: 'Inga dokument att visa',
      authorizedSignatorySigningLink: 'Firmatecknare Signeringslänk',
      guarantorSigningLink: 'Borgensman Signeringslänk',
      finalPayment: 'Slutbetalning',
      finalPaymentForObject:
        'Återbetalning av kredit för objekt med reg.nr. {plateNumber}',
      firstPayment: 'Första delbetalning',
      paidOutDate: 'Betald den {paidOutDate}',
      directPayment: 'Betala nu',
    },
    placeHolders: {
      privateSellerName: 'Ange namn',
      companyContactPerson: 'Ange kontaktperson',
      organisationNumber: 'Ange organisationsnummer',
      phoneNumber: '07XXXXXXXX',
      organizationNumber: 'XXXXXXXXXX',
      personalId: 'ÅÅÅÅMMDDXXXX',
      numberPlate: 'ABC123',
      objectVat: 'Momsbil',
      brand: 'Ange märke',
      model: 'Ange modell',
      yearModel: 'Ange årsmodell',
      metricInKilometers: 'Ange mätarställning (km)',
    },
    values: {
      usedDaysOfTotalDays: '{usedDays} av {totalDays} dagar',
      usedDays: '{usedDays} dagar',
      interest: '{interest} ränta',
      installmentPercentage: 'Motsvarar {percentage}% av fordonets pris',
      appliedInterest: '{interest}% månadsränta',
      agreementFee: '{amount} uppläggningsavgift',
    },
    'payment-details': {
      choosePaymentMethods: 'Välj betalningsmetod',
      payByDirectPayment: 'Betala direkt',
      directPaymentDescription1:
        'MyMoney samarbetar med Finshark för direktbetalning',
      directPaymentDescription2:
        'Du betalar enkelt från ditt önskade bankkonto, med säker verifiering via ditt BankID.',
      inPartnershipWith: 'I samarbete med',
      goToPayment: 'Gå till säker betalning',
      payByInvoice: 'Betala med faktura',
      invoiceDescription: 'Betalning sker via faktura',
      downloadInvoice: 'Ladda ner fakturan',
      changePaymentMethod: 'Ändra betalningsmetod',
    },
  },
  'point-shop': {
    pageHeadline: 'Poängshop',
    balanceLeft: 'Ditt saldo: {balanceLeft} p',
    searchPlaceholder: 'Sök produkt',
    searchResultsMatching:
      '{totalElements} sökträffar som matchar "{termSearched}"',
    filterByPoints: 'Visa endast produkter som jag kan köpa',
    searchPaginationInfo:
      'Visar {paginationFirstItemNumber} - {paginationLastItemNumber} av totalt {totalElements} produkter',
    stock: {
      expectedInbound: 'Åter {expectedInbound}',
      outOfStock: 'Slutsåld',
    },
    cart: {
      emptyCart: 'Varukorgen är tom',
      multipleProducts: '{itemsInCart} produkter',
      singleProduct: '{itemsInCart} produkt',
      total: 'Totalt',
      toCheckout: 'Till varukorgen',
    },
    returnBackToPointShop: 'Tillbaks till poängshoppen',
  },
  'point-shop-article': {
    addToCart: '+ Lägg till i varukorg',
  },
  'point-shop-checkout': {
    pageHeadline: 'Varukorgen',
    packageDeliveryTo: 'Paketet kommer att levereras till',
    att: 'Att: {fullName}',
    denyBoxAddress:
      'Vi kan tyvärr inte skicka paket till box-adresser, vänligen ange en annan adress.',
    form: {
      att: 'Att',
      address: 'Adress',
      zip: 'Postnummer',
      city: 'Stad',
      errorBoxAddress: 'Adress får inte vara en Box-adress',
    },
    sendOrder: 'Skicka beställning',
    overspent:
      'Du har överskridit värdet av dina tillgängliga poäng, vänligen justera din varukorg.',
    insufficientQuantity:
      'Din valda artikel “{articleName}” har för lågt lagersaldo.',
    confirmation: {
      orderHasBeenSentTitle: 'Din beställning är mottagen!',
      orderHasBeenSentDescription:
        'Vi har tagit emot beställningen och en bekräftelse kommer inom kort att mailas till dig.',
      orderHasBeenPartiallySentTitle: 'Din order är mottagen',
      orderHasBeenPartiallySentDescription:
        'Observera att alla produkter i din order tyvärr inte finns i lager. Det innebär att du kan komma att få en delleverans. Kontakta oss i chatten, per mail partner@mymoney.se eller på telefon 0770 170 60 70 om du har några frågor.',
      orderSentErrorTitle: 'O nej, din order misslyckades',
      orderSentErrorDescription:
        'Tyvärr gick något fel med din order. Kontakta oss i chatten, per mail partner@mymoney.se eller på telefon 0770 170 60 70 så hjälper vi dig.',
    },
  },
  report: {
    pageHeadline: 'Rapporter',
    subNavigation: {
      businessFinanceMonthlyStatement: 'Månadsrapport Lagerfinans',
      activeBusinessFinanceStatement: 'Mina aktiva Lagerfinanser',
    },
    tableHeader: {
      month: 'Månad',
      interest: 'Ränta',
    },

    error: {
      title: {
        list: {
          noAccess: 'Vi kunde inte hitta rapporter!',
          notFound: 'Vi kunde inte hitta rapporter!',
          wrongData: 'Något gick fel',
          notAcceptable: 'Något gick fel',
        },
      },
      message: {
        list: {
          noAccess: 'Rapporter är inte tillgängliga.',
          notFound: 'Rapporter är inte tillgängliga.',
          wrongData: 'Rapporter är inte tillgängliga.',
          notAcceptable: 'Månaden är inte tillgänglig för nedladdning',
        },
      },
    },
  },
  commission: {
    pageHeadline: 'Provision',
    totalCommissionCurrentYear: 'Utbetald provision {year}:',
    tableHeader: {
      month: 'Månad',
      commission: 'Provision',
      adjustment: 'Justering',
      paidOut: 'Utbetalt',
    },
    onGoing: 'Pågående',

    error: {
      title: {
        list: {
          noAccess: 'Vi kunde inte hitta provisioner!',
          notFound: 'Vi kunde inte hitta provisioner!',
          wrongData: 'Något gick fel',
          notAcceptable: 'Något gick fel',
        },
      },
      message: {
        list: {
          noAccess: 'Provisioner är inte tillgängliga.',
          notFound: 'Provisioner är inte tillgängliga.',
          notAcceptable: 'Månaden är inte tillgänglig för nedladdning',
        },
      },
    },
  },
  organisation: {
    pageHeadline: 'Organisation',
    usersHeadline: 'Användare',
    paginationShowing:
      'Visar {first}-{last} av totalt {totalElements} användare',
    searchPlaceholder: 'Sök efter namn',
    label: {
      companyName: 'Företagsnamn',
      organisationNumber: 'Organisationsnummer',
      emailAddress: 'E-postadress',
      phoneNumber: 'Telefonnummer',
      bankAccountType: 'Kontotyp',
      bankAccountNumber: 'Kontonummer',
      bankAccountClearingNumber: 'Clearingnummer',
      employeeName: 'Namn',
      facilityName: 'Anläggning',
      IBAN: 'IBAN',
      BIC: 'BIC',
    },
    accountTypes: {
      BANKGIRO: 'Bankgiro',
      PLUSGIRO: 'Plusgiro',
      BBAN: 'Bankkonto',
      IBAN: 'IBAN',
      FI_BANK: 'Bankkonto',
      MISSING: 'Saknas',
    },
  },
  market: {
    pageHeadline: 'Market',
    numberPlate: 'Registreringsnummer: {numberPlate}',
    filterResults: '{filterAmount} produkter i lager',
    backToMarket: 'Tillbaka till Market',
    purchase: 'Köp',
    purchaser: 'Köpare',
    companyDetails: 'Företagsuppgifter',
    acceptTerms: 'Acceptera villkoren',
    iAcceptTerms: 'Jag accepterar användarvillkoren för Pay Market',
    observe: 'Observera att',
    bindingPurchase: 'Köpet är bindande',
    soldAsIs: 'Bilen säljes i befintligt skick',
    sellerSendsInvoice:
      'Säljaren skickar faktura för hela bilens belopp direkt till dig',
    transportOfVehicle: 'Transport av fordon ingår ej',
    confirmPurchase: 'Slutför köp',
    confirmedPurchaseTitle: 'Din bil är beställd',
    confirmedPurchaseDialog:
      'Vi har tagit emot din beställning och du kommer att få ett bekräftelsemail inom kort med mera information.',
    error: {
      title: {
        list: {
          notFound: 'Kunde inte hitta några annonser',
        },
        detail: {
          notFound: 'Vi kunde inte hitta bilen!',
        },
        confirm: {
          wrongData: 'Något gick fel',
          notFound: 'Det gick inte att köpa bilen!',
        },
      },
      message: {
        list: {
          notFound: 'Kunde inte hitta några annonser.',
        },
        detail: {
          notFound: 'Bilen är inte längre tillgänglig.',
        },
        confirm: {
          wrongData: 'Det gick inte att genomföra köpet.',
          notFound: 'Bilen du försökte köpa är inte längre tillgänglig.',
        },
      },
    },
    ads: {
      fuelTypes: {
        DIESEL: 'Diesel',
        ELECTRIC: 'El',
        GASOLINE: 'Bensin',
        HYBRID: 'Hybrid',
      },
      gearTypes: {
        AUTOMATIC: 'Automat',
        MANUAL: 'Manuell',
      },
    },
    fuelTypes: {
      DIESEL: 'Diesel',
      ELECTRIC: 'El',
      GASOLINE: 'Bensin',
      HYBRID: 'Hybrid',
    },
    gearTypes: {
      AUTOMATIC: 'Automat',
      MANUAL: 'Manuell',
    },
    distanceMil: '{distance} mil',
    distanceKilometers: '{distance} kilometer',
  },
  marketTermsModal: {
    modalTitle: 'Användarvillkor Market',
    aboutService: {
      title: 'Om tjänsten',
      text: 'I MyMoney Market (nedan kallad ”Annonssplatsen”) kan anslutna samarbetspartners (nedan kallade ”Återförsäljare”) genomföra köp av varor från tredje part (nedan kallad ”Säljare”). Personer anställda hos Återförsäljare (nedan kallade ”Användare”) får som representant för Återförsäljare tillgång till Marknadsplatsen genom ett användarkonto. Genom att använda Marknadsplatsen godkänner och omfattas Återförsäljare och Användare av dessa Användarvillkor (”Villkoren”). Tjänsten tillhandahålls av MyMoney Europe AB, 556702-1679 (”MyMoney”). MyMoney avgör fritt från tid till annan vilka Återförsäljare och Användare som har tillgång till tjänsten.',
    },
    agreementAndOrder: {
      title: 'Avtal och beställning',
      text: 'För att kunna göra ett köp via Marknadsplatsen måste Återförsäljare och Användare godkänna villkoren. Vid köp av vara via Marknadsplatsen är det Säljare och Återförsäljare som ingår avtal. MyMoney tillhandahåller Marknadsplatsen och förmedlar information mellan Säljare och Återförsäljare/Användare men ansvarar inte på något sätt för sålda varors skick, leverans, riktighet i varans beskrivning eller annat relaterat till köpet. Säljare reserverar sig för slutförsäljning samt eventuella bild- och skrivfel på Marknadsplatsen, exempelvis fel i produktbeskrivning eller teknisk specifikation, felaktiga priser samt prisjusteringar eller felaktig information angående om en vara finns i lager. Säljaren har rätt att korrigera sådana eventuella fel och att ändra eller uppdatera informationen när som helst. All bildinformation på marknadsplatsen ska uteslutande ses som illustrationer. Sådana illustrationer kan inte garantera varans exakta utseende, funktioner eller ursprung. MyMoney ansvarar inte för information på Annonsplatsen som kommer från Säljare. Ett genomfört köp på Annonsplatsen är bindande och faktura på avtalad köpeskilling kommer att skickas från Säljaren till Återförsäljaren.',
    },
    deliveryAndTransport: {
      title: 'Leverans och transport',
      text: 'Leverans av köpt vara kommer att ske när avtalad köpeskilling kommit Säljaren tillhanda. Möjliga leveranssätt och fraktkostnader framgår av varans annons och avtalas mellan Säljare och Återförsäljare/Användare.',
    },
    acceptTermsButtonText: 'Acceptera villkor',
  },
  'my-profile': {
    userInformation: 'Användaruppgifter',
    updateUserInformation: 'Uppdatera användaruppgifter',
    userInformationUpdated: 'Användaruppgifter uppdaterade',
    userInformationUpdatedDescription: 'Dina användaruppgifter är ändrade!',

    notifications: 'Notifikationer',
    notifyWhen: 'Notifiera mig om',
    notifyOn: 'Notifiera mig via',
    statusLabels: {
      bankApproved: 'Godkända ansökningar',
      bankDenied: 'Avslagna ansökningar',
      signed: 'Signerade ansökningar',
      paidOut: 'Utbetalda ansökningar',
      bankSupplement: 'Komplettering',
    },
    channelLabels: {
      email: 'Via mail',
      sms: 'Via SMS',
    },
    language: {
      title: 'Kiel / Språk',
      subtitle:
        'Haluan nähdä järjestelmän ja vastaanottaa viestintää: \n' +
        'Jag vill se systemet och få kommunikation på:',
      labelSV: 'Svenska - SV',
      labelFI: 'Suomeksi - FI',
    },
    error: {
      title: {
        userInformation: {
          noAccess: 'Vi kunde inte uppdatera användaruppgifter!',
          notFound: 'Vi kunde inte uppdatera användaruppgifter!',
          wrongData: 'Något gick fel',
        },
        subscriptions: {
          noAccess: 'Vi kunde inte uppdatera notifikationer!',
          notFound: 'Vi kunde inte uppdatera notifikationer!',
          wrongData: 'Något gick fel',
        },
      },
      message: {
        userInformation: {
          noAccess: 'Det går inte att uppdatera användaruppgifter just nu.',
          notFound: 'Det går inte att uppdatera användaruppgifter just nu.',
          wrongData: 'Något gick fel',
        },
        subscriptions: {
          noAccess: 'Det går inte att uppdatera notifikationer just nu.',
          notFound: 'Det går inte att uppdatera notifikationer just nu.',
          wrongData: 'Något gick fel',
        },
      },
    },
  },
  maintenance: {
    title: 'Underhåll pågår',
    body: 'Öppnar inom kort igen.',
    reload: 'Ladda om sidan',
  },
  'vehicle-information': {
    noMatch: 'Hittade inget fordon med registreringsnummer "{numberPlate}".',
    errorMessage:
      'Slagning mot registret är inte möjligt just nu. Ange fordonsinformation manuellt.',
    labels: {
      metricInKilometers: 'Mätarställning (km)',
      getVehicle: 'Hämta fordon',
      numberPlate: 'Registreringsnummer',
    },
    placeholders: {
      metricInKilometers: 'Ange mätarställning (km)',
      numberPlate: 'ABC123',
    },
  },
};
