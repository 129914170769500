<template>
  <div class="skeleton-loader-rectangle h-3 rounded" />
</template>

<script>
export default {
  name: 'SkeletonLoaderRectangle',
};
</script>

<style scoped>
.skeleton-loader-rectangle {
  background-color: #edf2ee;
}
</style>
