<template>
  <providers>
    <maintenance v-if="isAppInMaintenance" />
    <div v-else-if="!isLoadingRouter" id="app" class="mymoney-pay-base-layout">
      <notifications />
      <login-layout v-if="useLoginLayout" />
      <full-screen-layout
        class="overflow-hidden"
        v-else-if="useFullScreenLayout"
      />
      <standard-layout class="overflow-hidden" v-else />
    </div>
  </providers>
</template>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
</style>
<script>
import { computed, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import posthog from 'posthog-js';

import FullScreenLayout from '@/layouts/FullScreenLayout';
import LoginLayout from '@/layouts/LoginLayout';
import StandardLayout from '@/layouts/StandardLayout';
import Layouts from '@/constants/Layouts';
import Providers from '@/components/Providers.vue';
import Notifications from '@/components/notifications/Notifications.vue';
import Maintenance from '@/views/Maintenance';

export default {
  components: {
    FullScreenLayout,
    LoginLayout,
    StandardLayout,
    Notifications,
    Providers,
    Maintenance,
  },

  setup() {
    const { locale } = useI18n({ useScope: 'global' });
    const route = useRoute();
    const { state, getters } = useStore();
    const isLoadingRouter = computed(() => state.router.isLoading);

    const { VUE_APP_IN_MAINTENANCE } = process.env;

    const isAppInMaintenance = VUE_APP_IN_MAINTENANCE === 'true';

    const useFullScreenLayout = computed(
      () => route.meta.layout === Layouts.FULL_SCREEN
    );
    const useLoginLayout = computed(() => route.meta.layout === Layouts.LOGIN);

    watchEffect(() => {
      if (state.user.informationFetched) {
        const userLocale = getters['user/getLocale'];

        locale.value = userLocale;
        document.documentElement.setAttribute('lang', userLocale);

        posthog.identify(state.user.id, {
          email: state.user.email,
          name: `${state.user.firstName} ${state.user.lastName}`,
          dealer: state.user.partner.name,
          facility: state.user.facility.name,
          kamId: state.user.facility.keyAccountManagerId,
        });
      }
    });

    return {
      isLoadingRouter,
      isAppInMaintenance,
      useFullScreenLayout,
      useLoginLayout,
    };
  },
};
</script>

<style></style>
