<template>
  <div class="flex gap-2 p-4 sm:flex-col sm:gap-4 md:flex-row md:gap-8 md:p-0">
    <p class="flex flex-col justify-center gap-1 text-sm leading-4">
      <span class="whitespace-nowrap">
        <svg
          class="inline h-4 align-text-top"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
          ></path>
        </svg>
        <a :href="`tel:${contactPhoneNumber}`">
          {{ contactPhoneNumber }}
        </a>
      </span>
      <span class="whitespace-nowrap">
        <svg
          class="inline h-4 align-text-top"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
          ></path>
          <polyline points="22,6 12,13 2,6"></polyline>
        </svg>
        <a :href="`mailto:${contactEmail}`">
          {{ contactEmail }}
        </a>
      </span>
    </p>
    <p class="grid-fixed-cols grid gap-x-1 text-sm leading-4">
      <span class="truncate">{{ translation('general.workingDays') }}</span>
      <span class="whitespace-nowrap">
        {{ openingHoursWorkingDay }}
      </span>
      <span class="truncate">{{ translation('general.saturday') }}</span>
      <span class="whitespace-nowrap">
        {{ openingHoursSaturday }}
      </span>
      <span class="truncate">{{ translation('general.sunday') }}</span>
      <span class="whitespace-nowrap">
        {{ openingHoursSunday }}
      </span>
    </p>
  </div>
</template>
<script>
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t: translation } = useI18n();
    const contactPhoneNumber = process.env.VUE_APP_CONTACT_PHONE_NUMBER;
    const contactEmail = process.env.VUE_APP_CONTACT_EMAIL;

    function getOpeningHours(openingHour) {
      return openingHour === 'closed'
        ? translation('general.openingHourClosed')
        : openingHour;
    }

    const openingHoursWorkingDay = getOpeningHours(
      process.env.VUE_APP_OPENING_HOURS_WORKING_DAYS
    );
    const openingHoursSaturday = getOpeningHours(
      process.env.VUE_APP_OPENING_HOURS_SATURDAY
    );
    const openingHoursSunday = getOpeningHours(
      process.env.VUE_APP_OPENING_HOURS_SUNDAY
    );

    return {
      translation,
      contactPhoneNumber,
      contactEmail,
      openingHoursWorkingDay,
      openingHoursSaturday,
      openingHoursSunday,
    };
  },
};
</script>
<style scoped>
.grid-fixed-cols {
  grid-template-columns: minmax(0, 1fr) 1fr;
}
</style>
