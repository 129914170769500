<template>
  <base-modal :fullscreen="true">
    <div class="max-w-2xl justify-center text-center">
      <div class="flex justify-center">
        <p
          class="mb-12 flex size-40 items-center justify-center rounded-full bg-orange-200 text-c9xl"
        >
          🚧
        </p>
      </div>

      <p class="mymoney-primary-text-color mb-2 text-4xl font-bold">
        {{ translation('maintenance.title') }}
      </p>

      <p class="maintenance-body-text mb-8 text-base text-secondary-gray">
        {{ translation('maintenance.body') }}
      </p>

      <div class="flex justify-center">
        <base-button
          class="w-48"
          size="large"
          variant="primary"
          @on-click="router.go()"
        >
          {{ translation('maintenance.reload') }}
        </base-button>
      </div>
    </div>
  </base-modal>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import BaseButton from '@/components/BaseButton';
import BaseModal from '@/components/BaseModal';

export default {
  name: 'Maintenance',

  components: { BaseButton, BaseModal },

  setup() {
    const { t: translation } = useI18n();
    const router = useRouter();

    return {
      translation,
      router,
    };
  },
};
</script>

<style scoped>
.maintenance-body-text {
  line-height: 1.38;
}
</style>
