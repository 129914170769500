import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import router from '@/store/modules/router';
import user from '@/store/modules/user';
import errorsStore from '@/store/errors/errorsStore';
import formsStore from '@/store/forms/formsStore';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['user', 'syncAtLogin'],
});

export default createStore({
  state: { ...errorsStore.state, ...formsStore.state, syncAtLogin: true },
  mutations: {
    ...errorsStore.mutations,
    ...formsStore.mutations,
    setSyncAtLogin(state, value) {
      state.syncAtLogin = value;
    },
  },
  actions: { ...errorsStore.actions, ...formsStore.actions },
  modules: { router, user },
  plugins: [vuexLocal.plugin],
});
