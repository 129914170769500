/* eslint-disable no-param-reassign */
import posthog from 'posthog-js';

export default {
  install(app) {
    const { VUE_APP_POSTHOG_API_KEY, VUE_APP_POSTHOG_API_HOST } = process.env;

    app.config.globalProperties.$posthog = posthog.init(
      VUE_APP_POSTHOG_API_KEY,
      {
        api_host: VUE_APP_POSTHOG_API_HOST,
      }
    );
  },
};
