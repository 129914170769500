<template>
  <div>
    <label class="relative">
      <input
        class="base-input search-field w-full rounded border py-4 pl-8 pr-4 font-normal sm:pl-12 sm:pr-20"
        :value="value"
        :placeholder="placeholder"
        type="text"
        @input="onUpdateInput"
      />

      <span class="absolute inset-y-0 left-0 flex items-center pl-3 sm:pl-5">
        <img src="@/assets/images/icons/magnifying-glass-icon.svg" alt="" />
      </span>

      <span
        v-if="loading"
        class="absolute inset-y-0 right-0 hidden items-center pr-5 sm:flex"
      >
        <img
          class="animate-spin"
          src="@/assets/images/loading-spinners/secondary-spinner.png"
          alt="loading"
        />
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'SearchField',

  props: {
    loading: Boolean,
    placeholder: String,
    value: String,
  },

  setup(props, { emit }) {
    function onUpdateInput(event) {
      emit('on-update-search', event.target.value);
    }

    return {
      onUpdateInput,
    };
  },
};
</script>

<style scoped>
.search-field {
  border-color: #e3e4e6;
  box-shadow:
    0 8px 16px 0 rgba(0, 0, 0, 0.06),
    0 3px 6px 0 rgba(0, 0, 0, 0.04);
  font-size: 100%;
}
</style>
