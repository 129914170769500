<template>
  <div class="bg-hero-image bg-cover bg-center bg-no-repeat">
    <div
      class="relative m-auto flex size-full min-h-screen flex-col items-center justify-center bg-white sm:max-w-lg sm:bg-transparent"
    >
      <router-view />
    </div>
  </div>
</template>

<style></style>

<script>
export default {
  name: 'LoginLayout',
};
</script>

<style>
.bg-hero-image {
  background-image: url('../assets/images/mymoney-pay-hero.jpg');
}
</style>
