export default {
  state: {
    errors: [],
  },
  mutations: {
    POST_ERROR: (state, payload) => {
      state.errors.push(payload);
    },
    REMOVE_ERROR: (state, payload) => {
      const index = state.errors.findIndex((field) => field === payload);
      if (index > -1) {
        state.errors.splice(index, 1);
      }
    },
  },
  actions: {
    SET_ERROR: (context, errorField) => {
      context.commit('POST_ERROR', errorField);
    },
    REMOVE_ERROR: (context, errorField) => {
      context.commit('REMOVE_ERROR', errorField);
    },
  },
};
