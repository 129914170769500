<template>
  <div class="flex-col" ref="root">
    <div
      id="toggle-user-menu"
      class="flex cursor-pointer pr-5"
      @click="toggleUserMenu"
    >
      <p
        class="the-header-user-menu-bg-color-lighter-green flex size-10 items-center justify-center rounded-full"
      >
        {{ initials }}
      </p>

      <div class="hidden items-center sm:flex">
        <div class="ml-3 mr-5">
          <p>{{ fullName }}</p>
          <p id="points" v-if="hasAccessToPointShop" class="text-xs">
            {{ numberFormat(pointBalance) }} p
          </p>
        </div>

        <img src="@/assets/images/icons/down-chevron-white-icon.svg" alt="" />
      </div>
    </div>

    <transition name="dropdown-slide">
      <base-dropdown-list
        v-if="showUserMenuOptions"
        :groupDivider="true"
        :options="menuOptions"
        class="user-menu-options absolute right-5 mt-1"
        @on-click="onClickUserLink"
      />
    </transition>
  </div>
</template>

<script>
import { computed, onMounted, onBeforeUnmount, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import BaseDropdownList from '@/components/BaseDropdownList';

import logOut from '@/compositions/logOut';
import { useToggle } from '@/compositions/useToggle';
import Roles from '@/constants/Roles';
import TokenProxy from '@/proxies/TokenProxy';

export default {
  name: 'TheHeaderUserMenu',

  components: { BaseDropdownList },

  setup() {
    const { n: numberFormat, t: translation } = useI18n();
    const router = useRouter();
    const { dispatch, getters, state } = useStore();

    const root = ref(null);

    const fullName = getters['user/fullName'];
    const initials = getters['user/initials'];

    const pointBalance = computed(() => state.user.pointBalance);

    const hasAccessToPointShop = getters['user/userHasAnyOfRoles'](
      Roles.POINT_SHOP
    );

    const menuOptions = [
      {
        options: [
          {
            value: 'UserMyProfile',
            label: translation('general.navigation.myProfile'),
          },
        ],
      },
      {
        options: [
          {
            value: 'logOut',
            label: translation('general.navigation.logOut'),
            leadIcon: 'logout-icon.svg',
          },
        ],
      },
    ];

    const { isVisible: showUserMenuOptions, toggleVisible: toggleUserMenu } =
      useToggle();

    function onClickLogOut() {
      const tokenProxy = new TokenProxy();

      tokenProxy
        .revokeToken()
        .then(logOut)
        .catch((error) => ({ error }));
    }

    function onClickUserLink(link) {
      toggleUserMenu();

      switch (link.value) {
        case 'UserMyProfile':
          router.push({ name: link.value });
          break;
        case 'logOut':
          onClickLogOut();
          break;
        default:
          break;
      }
    }

    function onClickOutside(e) {
      if (showUserMenuOptions.value && !root?.value?.contains(e.target)) {
        toggleUserMenu();
      }
    }

    watch(showUserMenuOptions, async () => {
      if (hasAccessToPointShop && showUserMenuOptions.value) {
        await dispatch('user/syncPointBalance');
      }
    });

    onMounted(() => {
      document.addEventListener('click', onClickOutside);
    });
    onBeforeUnmount(() => {
      document.removeEventListener('click', onClickOutside);
    });

    return {
      numberFormat,
      root,
      hasAccessToPointShop,
      fullName,
      initials,
      pointBalance,
      menuOptions,
      showUserMenuOptions,
      toggleUserMenu,
      onClickUserLink,
    };
  },
};
</script>

<style scoped>
.the-header-user-menu-bg-color-lighter-green {
  background-color: #0e4f48;
}

.user-menu-options {
  min-width: 13.75rem;
}
</style>
