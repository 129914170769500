import { reactive } from 'vue';
import { TYPES } from '@/constants/notifications';

let notificationId = 0;

const notifications = reactive([]);

/**
 * Remove a notification
 *
 * @param {Number} idToRemove - id of notification to remove
 */
const removeNotification = (idToRemove) => {
  notifications.splice(
    notifications.findIndex(({ id }) => id === idToRemove),
    1
  );
};

/**
 * Add a notification
 *
 * @param {Object} params
 * @param {string} params.title - title of notification
 * @param {string} params.message - message of notification
 * @param {TYPES} [params.type=TYPES.info] - notification type ]
 * @param {string} [params.timeout] - optional timeout value for when notification should be removed
 */
const addNotification = ({ title, message, type = TYPES.info, timeout }) => {
  const newNotification = {
    title,
    message,
    type,
    id: (notificationId += 1),
  };

  notifications.push(newNotification);

  if (timeout) {
    setTimeout(() => removeNotification(newNotification.id), timeout);
  }
};

export default function useNotifications() {
  return {
    notifications,
    addNotification,
    removeNotification,
  };
}
