<template>
  <div class="relative" ref="root">
    <div class="cursor-pointer" @click="toggleDropdown">
      <div v-if="isFilter" class="flex items-center">
        <p class="base-dropdown-filter-text-color mr-2 text-sm">{{ label }}:</p>

        <p
          v-if="modelValueLabel"
          class="mr-1 font-semibold text-primary-accent"
        >
          {{ modelValueLabel }}
        </p>
        <input type="hidden" :value="modelValue" />

        <img src="@/assets/images/icons/down-chevron-green-icon.svg" alt="" />
      </div>

      <div v-else>
        <p
          v-if="label"
          class="base-dropdown-label mb-2 font-semibold uppercase tracking-wide"
        >
          {{ label }}
        </p>

        <div class="relative">
          <div
            class="base-dropdown min-h-10 w-full min-w-48 rounded border px-3"
            :class="[inputSize, errorStyle]"
            :disabled="disabled"
          >
            <p class="select-none text-left">
              <span v-if="modelValueLabel">{{ modelValueLabel }}</span>
              <span v-else class="base-dropdown-placeholder-text font-normal">
                {{ placeholder }}
              </span>
            </p>
          </div>

          <div
            class="absolute inset-y-0 right-0 flex items-center border-l px-3"
            :class="[errorStyle]"
          >
            <img src="@/assets/images/icons/dropdown-chevron-icon.svg" alt="" />
          </div>
        </div>
      </div>

      <input
        class="hidden-input"
        :name="name"
        :value="modelValue"
        :required="required"
      />
    </div>

    <transition name="dropdown-slide">
      <base-dropdown-list
        v-if="showDropdownList"
        class="absolute z-10 mt-2"
        :class="[isFilter ? 'min-w-48' : 'min-w-full']"
        :groupDivider="true"
        :options="options"
        :selected="modelValue"
        @on-click="onClickDropdownItem"
      />
    </transition>
  </div>
</template>

<script>
import { onMounted, onBeforeUnmount, ref, computed } from 'vue';

import BaseDropdownList from '@/components/BaseDropdownList';

import { useToggle } from '@/compositions/useToggle';

export default {
  name: 'BaseDropdown',

  components: { BaseDropdownList },

  props: {
    disabled: Boolean,
    error: Boolean,
    label: String,
    value: [Number, String],
    modelValue: [Number, String],
    name: String,
    options: Array,
    placeholder: String,
    size: String,
    variant: String,
    required: Boolean,
  },

  setup(props, { emit }) {
    const root = ref(null);
    const isFilter = computed(() => props.variant === 'filter');

    const inputSize = computed(() => {
      switch (props.size) {
        case 'large':
          return 'py-4';
        default:
          return 'py-2';
      }
    });

    const errorStyle = computed(() =>
      props.error ? 'base-dropdown-error' : ''
    );

    const modelValueLabel = computed(() => {
      let selectedOption;
      if (props.options) {
        selectedOption = props.options
          .flatMap((section) => section.options)
          .find((option) =>
            [props.modelValue, props.value].includes(option.value)
          );
      }

      return selectedOption ? selectedOption.label : false;
    });

    const { isVisible: showDropdownList, toggleVisible: toggleDropdown } =
      useToggle();

    function onClickDropdownItem(item) {
      emit('on-value-change', item.value);
      emit('update:modelValue', item.value);
      toggleDropdown();
    }

    function onClickOutside(e) {
      if (showDropdownList.value && !root?.value?.contains(e.target)) {
        toggleDropdown();
      }
    }

    onMounted(() => {
      document.addEventListener('click', onClickOutside);
    });
    onBeforeUnmount(() => {
      document.removeEventListener('click', onClickOutside);
    });

    return {
      root,
      isFilter,
      inputSize,
      errorStyle,
      modelValueLabel,
      showDropdownList,
      toggleDropdown,
      onClickDropdownItem,
    };
  },
};
</script>

<style scoped>
.base-dropdown-label {
  font-size: 0.625rem;
  color: #4b4b4b;
}

.base-dropdown {
  @apply border-gray-400;
}

.base-dropdown:disabled {
  background-color: #d9d9d9;
}

.base-dropdown:focus {
  @apply border-primary-accent outline-none;
  box-shadow: 0 0 3px #157a6f;
  -moz-box-shadow: 0 0 3px #157a6f;
  -webkit-box-shadow: 0 0 3px #157a6f;
}

.base-dropdown:not(:active):not(.base-input-error):hover:enabled {
  border-color: #999999;
}

.base-dropdown-placeholder-text {
  color: #999999;
}

.base-dropdown-filter-text-color {
  color: #666666;
}

.base-dropdown-error {
  border-color: #da1e28;
}

.hidden-input {
  position: absolute;
  padding: 0;
  margin: 0;
  height: 0;
  width: 100%;
  box-shadow: none;
  border-color: transparent;
}
</style>
